<template>
  <footer class="bg-black text-white rc-hidden lg:block">
    <nav
      class="rc-container mx-auto py-16 px-6 grid gap-6 lg:grid-cols-4 justify-between max-w-screen-3xl"
    >
      <ul v-for="menu in levelOneFooterMenu" :key="`level-one-${menu.id}`">
        <li>
          <h3 class="text-blue-500 text-xl font-bold pb-4 m-0">
            {{ menu.title }}
          </h3>
          <ul v-if="menu.id">
            <li
              v-for="link in getLevelTwoMenu(menu.id)"
              :key="`level-two-${link.id}`"
              class="py-1"
            >
              <!-- Check if it's a truly external URL or /blog route -->
              <a
                v-if="link.externalUrl || isNuxtRoute(link.pageUrl)"
                :href="link.pageUrl"
                class="text-white pb-3 hover:text-blue-500"
              >
                {{ link.title }}
              </a>
              <router-link
                v-else
                :to="link.pageUrl ? localizedRoute(link.pageUrl) : ''"
                class="text-white pb-3 hover:text-blue-500"
              >
                {{ link.title }}
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
      <div class="flex flex-col items-start gap-4">
        <RibbleLogo class="h-16" />
        <h3 class="text-blue-500 text-xl font-bold my-4">Follow Us</h3>
        <ul>
          <li
            v-for="link in footerSocialLinks"
            :key="link.linkUrl"
            class="mb-4"
          >
            <a
              :href="link.linkUrl"
              class="hover:text-blue-500 flex gap-3 items-center"
            >
              <SocialIcons :icon-data="link.linkIcon" />
              {{ link.linkText }}
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex';
import RibbleLogo from './RibbleLogo';
import SocialIcons from './SocialIcons';
import MobileFooter from './MobileFooter';
import { localizedRoute } from '@vue-storefront/core/lib/multistore';

export default {
  name: 'DesktopFooter',
  components: {
    RibbleLogo,
    SocialIcons,
    MobileFooter
  },
  computed: {
    ...mapGetters('craftCms', [
      'levelOneFooterMenu',
      'levelTwoFooterMenu',
      'footerSocialLinks'
    ])
  },
  methods: {
    getLevelTwoMenu(parentId) {
      return this.levelTwoFooterMenu(parentId);
    },
    // Define isExternalRoute method to check for /blog
    isNuxtRoute(url) {
      // Handle /blog as an external route
      if (!url) return false;
      return url.startsWith('/blog');
    }
  }
};
</script>
