<template>
  <div>
    <FacebookLink v-if="iconData === 'facebook'" />
    <TwitterLink v-if="iconData === 'twitter'" />
    <InstagramLink v-if="iconData === 'instagram'" />
    <StravaLink v-if="iconData === 'strava'" />
    <YoutubeLink v-if="iconData === 'youtube'" />
  </div>
</template>

<script>
import FacebookLink from './FacebookLink';
import TwitterLink from './TwitterLink';
import InstagramLink from './InstagramLink';
import StravaLink from './StravaLink';
import YoutubeLink from './YoutubeLink';

export default {
  name: 'SocialIcons',
  props: {
    iconData: {
      type: String,
      required: true
    }
  },
  components: {
    FacebookLink,
    TwitterLink,
    InstagramLink,
    StravaLink,
    YoutubeLink
  }
};
</script>
