<template>
  <div :class="product && !product.parent_bike_id ? 'pos-header' : 'pos-header-hide'">
    <header class="flex justify-between items-center">
      <h1>
        <a :href="isKiosk ? '/bikes' : null">
          <img width="auto" height="41px" src="/assets/logo.svg" alt="Ribble Cycles">
        </a>
      </h1>
    </header>
    <kiosk-category-menu v-if="isKiosk && !isParent" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import KioskCategoryMenu from 'theme/components/theme/bikebuilder/external/KioskCategoryMenu';

export default {
  name: 'HeaderPos',
  components: {
    KioskCategoryMenu
  },
  computed: {
    ...mapGetters('epos', ['isKiosk', 'posData']),
    ...mapGetters('product', ['getCurrentProduct']),
    product () {
      return this.getCurrentProduct;
    },
    isParent () {
      return this.getCurrentProduct.is_parent_bike;
    }
  }
}
</script>

<style scoped>
.pos-header {
  z-index: 3;
  width: 100%;
  position: absolute;
}
.pos-header-hide {
  display: none;
}
header {
  padding: 1rem 2rem;
  background-color: rgba(0,0,0,0.5);
}
h1 {
  margin: 0;
}
img {
  height: 41px;
}
</style>
