<template>
  <div class="px-3 max-w-screen-md mx-auto">
    <h3 class="mb-4 text-base text-blue-500 font-bold">
      {{
        $t(
          'Sign up to our newsletter to keep up to date on our products and innovations!'
        )
      }}
    </h3>
    <div class="relative pb-10">
      <form @submit.prevent="subscribe" class="flex items-center">
        <label class="rc" for="rc_name"></label>
        <input type="text" name="rc_name" autocomplete="off" class="rc" />
        <input
          v-model="email"
          autocomplete="email"
          required
          :placeholder="$t('Enter your email here')"
          class="border border-gray-400 appearance-none focus:outline-0 focus:ring-0 focus:outline-none px-2"
        />
        <button
          type="submit"
          class="text-white bg-blue-500 px-5 py-2 border flex items-center justify-center space-x-2 whitespace-nowrap"
        >
          <span class="uppercase font-bold text-lg">{{ $t('Sign Up') }}</span>
          <i class="fa fa-arrow-right" />
        </button>
      </form>
      <Transition name="fade">
        <div v-if="success" class="absolute -bottom-3 left-0 text-white py-5">
          {{ $t('You have been successfully subscribed to our newsletter!') }}
        </div>
      </Transition>
      <Transition name="fade">
        <div v-if="error" class="absolute -bottom-3 left-0 text-red-500 py-5">
          {{ error }}
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import config from 'config';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';

export default {
  name: 'RibbleNewsletter',
  data() {
    return {
      email: '',
      rc_name: null,
      success: false,
      error: ''
    };
  },
  computed: {
    siteCode() {
      const storeCode = currentStoreView().storeCode;
      if (storeCode === 'de_de_de') return 'de';
      return 'gb';
    },
    query() {
      return `mutation {
        subscribeEmailToNewsletter(email: "${this.email}") {
          status
        }
      }`;
    }
  },
  methods: {
    bloomreachTrack(email, action) {
      window.exponea.track('consent', {
        email: email,
        email_id: email,
        action: action,
        source: `${this.siteCode} footer`,
        category: 'newsletter',
        valid_until: 'unlimited',
        message: 'This consent was tracked from website footer' // additional attribute
      });
    },
    async subscribe() {
      this.error = '';
      if (this.rc_name === null) {
        try {
          const res = await axios.post(config.m2GraphqlUrl, {
            query: this.query
          });
          if (res.data.errors && res.data.errors.length) {
            this.error = res.data.errors[0].message;
            this.success = false;
            return;
          }
          if (
            res.data.data.subscribeEmailToNewsletter.status === 'SUBSCRIBED'
          ) {
            window.exponea.identify({ email_id: this.email });
            this.bloomreachTrack(this.email, 'accept');
            this.success = true;
            this.email = '';
            setTimeout(() => {
              this.success = false;
            }, 5000);
          }
        } catch (error) {
          console.error(error);
          this.success = false;
        }
      }
    }
  }
};
</script>

<style scoped>
.rc {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}
</style>
