<template>
  <ul class="grid" :class="`grid-cols-${gridCount}`">
    <li v-for="menuData in gridColsOneToThree" :key="menuData.id">
      <LevelTwoMenu :menu-data="menuData" />
    </li>
    <li
      v-if="activeTrending"
      :class="levelTwoCount < 3 ? 'col-start-3' : 'col-start-4'"
    >
      <Trending :trending-data="activeTrending" :grid-count="gridCount" />
    </li>
    <li v-for="menuData in gridColsThreePlus" :key="menuData.id">
      <LevelTwoMenu :menu-data="menuData" />
    </li>
  </ul>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import LevelTwoMenu from './LevelTwoMenu';
import Trending from './Trending';

export default {
  name: 'LevelOneMenu',
  components: { LevelTwoMenu, Trending },
  computed: {
    ...mapGetters('craftCms', ['activeLevelTwoData', 'activeTrending']),
    levelTwoCount() {
      return this.activeLevelTwoData.length;
    },
    gridCount() {
      if (!this.activeTrending) return 3;
      return this.levelTwoCount < 3 ? 3 : 4;
    },
    gridColsOneToThree() {
      return this.activeLevelTwoData.slice(0, 3);
    },
    gridColsThreePlus() {
      return this.activeLevelTwoData.slice(3, this.levelTwoCount);
    }
  },
  methods: {
    ...mapActions('craftCms', ['openMenu', 'closeMenu'])
  }
};
</script>
