<template>
  <BaseModal @close="toggleFullSpecCartModal">
    <template #heading>
      <h2
        class="mt-0 pt-[40px] font-normal text-xl text-center uppercase after:content-['*'] after:bg-black after:h-[1px] after:w-full after:block after:mt-[-20px]"
      >
        <span class="inline-block p-2 bg-white z-10 relative">
          {{ $t('Full Spec') }}
        </span>
      </h2>
    </template>
    <template #body>
      <FullSpecCart />
    </template>
  </BaseModal>
</template>

<script>
import { mapActions } from 'vuex';
import BaseModal from 'theme/components/theme/bikebuilder/ui/modals/BaseModal';
import FullSpecCart from 'theme/components/theme/bikebuilder/full-spec/FullSpecCart';

export default {
  name: 'FullSpecCartModal',
  components: {
    BaseModal,
    FullSpecCart
  },
  computed: {},
  methods: {
    ...mapActions('builderUi', ['toggleFullSpecCartModal'])
  }
};
</script>
