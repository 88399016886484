import Vue from 'vue'
import ProductPrice from 'theme/components/core/ProductPrice'
import { Logger } from '@vue-storefront/core/lib/logger'

export default Vue.directive('injectPrice', {
  // Called only once, when the directive is first bound to the element. This is where you can do one-time setup work.
  async bind (el, binding, vnode) {
    const priceElements = el.querySelectorAll('.product__price')

    priceElements.forEach(async (priceElement) => {
      if (priceElement && priceElement.getAttribute('data-sku')) {
        try {
          const product = await vnode.context.$store.dispatch('product/single', {
            options: {
              sku: priceElement.getAttribute('data-sku'),
              childSku: null
            },
            setCurrentProduct: false,
            skipCache: true
          }, { root: true })

          const ComponentClass = Vue.extend(ProductPrice);
          const instance = new ComponentClass({
            store: vnode.context.$store,
            propsData: { product: product }
          })
          instance.$mount()
          priceElement.replaceWith(instance.$el)
        } catch (error) {
          Logger.warn(error)()
          priceElement.remove()
        }
      } else if (priceElement) {
        priceElement.remove()
      }
    })
  }
})
