<template>
  <div class="rc-hidden-lg relative">
    <div
      class="bg-black overflow-y-scroll fixed bottom-0 z-50 w-screen"
      style="top: 68px"
    >
      <nav>
        <div
          class="border-b uppercase bg-white w-full text-left"
          v-for="levelOneMenu in levelOneData"
          :key="levelOneMenu.id"
          :id="`menu-${levelOneMenu.id}`"
        >
          <MobileMenuLink :level-one-menu="levelOneMenu" />
          <div class="overflow-hidden">
            <Transition name="drop">
              <ul
                v-if="getActiveMenuState === levelOneMenu.id"
                class="bg-white pl-3"
              >
                <li
                  v-for="levelTwoMenu in activeLevelTwoData"
                  :key="levelTwoMenu.id"
                >
                  <header v-if="levelTwoMenu.title" class="pt-6">
                    <button
                      type="button"
                      @click="navigatePage(levelTwoMenu.headingUrl)"
                      class="font-bold uppercase flex items-center gap-1"
                    >
                      <span>
                        {{ levelTwoMenu.title }}
                      </span>
                      <RightArrow class="h-5" />
                    </button>
                    <p class="p-0 text-xs italic text-gray-500">
                      {{ levelTwoMenu.description }}
                    </p>
                  </header>
                  <ul>
                    <li
                      v-for="(link, index) in getLevelTwoMenu(levelTwoMenu.id)"
                      :key="`level-three-${link.pageUrl}`"
                      class="py-3 text-sm"
                      :class="
                        getLevelTwoMenu(levelTwoMenu.id).length === index + 1
                          ? null
                          : 'border-b'
                      "
                    >
                      <button type="button" @click="navigatePage(link.pageUrl)">
                        {{ link.title }}
                      </button>
                    </li>
                  </ul>
                </li>
              </ul>
            </Transition>
          </div>
        </div>
      </nav>
      <div class="bg-black text-white p-4">
        <ul class="space-y-4">
          <li>
            <Transition name="fade" mode="out-in">
              <client-only>
                <LanguageSwitcher v-if="getCountry && multistoreEnabled" />
              </client-only>
            </Transition>
          </li>
          <li>
            <button type="button" @click="goToAccount">
              {{ isLoggedIn ? $t('My account') : $t('Register or Log In') }}
            </button>
          </li>
          <li>
            <button type="button" @click="goToHelp">
              {{ $t('Help') }}
            </button>
          </li>
          <li v-if="isLoggedIn">
            <button type="button" @click="logout">
              {{ $t('Logout') }}
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import config from 'config';
import ClientOnly from 'vue-client-only';
import { mapGetters, mapActions } from 'vuex';
import RightArrow from '../ui-icons/RightArrow';
import MobileMenuLink from './MobileMenuLink';
import { localizedRoute } from '@vue-storefront/core/lib/multistore';
import LanguageSwitcher from 'theme/components/core/LanguageSwitcher';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus';

export default {
  name: 'MobileMenu',
  components: { MobileMenuLink, RightArrow, LanguageSwitcher, ClientOnly },
  computed: {
    ...mapGetters('craftCms', [
      'showSearch',
      'levelOneData',
      'showMobileMenu',
      'activeLevelTwoData',
      'getActiveMenuState',
      'levelThreeMainMenu'
    ]),
    ...mapGetters('ayko_localisation', ['getCountry']),
    ...mapGetters('graphqlAccount', ['isLoggedIn']),
    multistoreEnabled() {
      return config.storeViews.multistore;
    }
  },
  methods: {
    ...mapActions('graphqlAccount', ['logout']),
    ...mapActions('craftCms', ['closeMobileMenu']),
    navigatePage(url) {
      if (this.$route.path !== url) {
        this.$router.push(localizedRoute(url)).catch((err) => {});
      }
      this.closeMobileMenu();
    },
    goToAccount() {
      if (this.$route.name === 'my-account') {
        this.closeMobileMenu();
        return;
      }
      if (this.isLoggedIn) {
        this.closeMobileMenu();
        this.$router.push(localizedRoute('/my-account')).catch((err) => {});
      } else {
        EventBus.$emit('modal-show', 'modal-signup');
      }
    },
    goToHelp() {
      this.closeMobileMenu();
      this.$router.push(localizedRoute('/help-centre')).catch((err) => {});
    },
    getLevelTwoMenu(parentId) {
      return this.levelThreeMainMenu(parentId);
    }
  }
};
</script>

<style scoped>
.drop-enter-active {
  transition: all 0.5s ease;
}

.drop-leave-active {
  transition: all 0.01s;
}

.drop-enter {
  transform: translate(0, -100%);
}
.drop-leave-to {
  transform: translate(0, -100%);
}

.slide-enter-active {
  transition: all 0.5s ease-out;
}

.slide-leave-active {
  transition: all 0.5s ease;
}

.slide-enter {
  transform: translate(-100%, 0);
}
.slide-leave-to {
  transform: translate(-100%, 0);
}
@media (min-width: 1024px) {
  .rc-hidden-lg {
    display: none;
  }
}
</style>
