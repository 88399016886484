<template>
  <modal name="modal-signup" :width="620">
    <RibbleLogin v-if="activeElem === 'login'" />
    <RibbleRegister v-if="activeElem === 'register'" />
    <RibbleResetPassword v-if="activeElem === 'forgot-pass'" />
  </modal>
</template>

<script>
// NOT USED
import { mapState } from 'vuex';
import Modal from 'theme/components/core/Modal';
import RibbleLogin from 'theme/components/theme/bikebuilder/graphql-auth/RibbleLogin.vue';
import RibbleRegister from 'theme/components/theme/bikebuilder/graphql-auth/RibbleRegister.vue';
import RibbleResetPassword from 'theme/components/theme/bikebuilder/graphql-auth/RibbleResetPassword.vue';

export default {
  name: 'SignUp',
  computed: {
    ...mapState({
      activeElem: (state) => state.ui.authElem
    })
  },
  components: {
    Modal,
    RibbleLogin,
    RibbleRegister,
    RibbleResetPassword
  }
};
</script>
