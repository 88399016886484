<template>
  <Transition name="fadeThree">
    <div
      v-if="menuLink"
      @mouseenter="openMenu(menuItem.id)"
      :class="isActive ? 'bg-white text-black' : 'text-white'"
    >
      <router-link
        :to="localizedRoute(menuLink)"
        class="flex items-center space-x-2 px-2 py-5 uppercase font-bold w-full justify-center"
        :class="menuLinkCss"
        :style="menuLinkStyles"
        @click.native="closeMenu"
      >
        <span class="font-featured whitespace-nowrap" :style="menuLinkStyles">
          {{ menuItem.title }}
        </span>
        <DownArrow class="w-4 h-4" :class="hasChildren ? null : 'hidden'" />
      </router-link>
    </div>
  </Transition>
</template>

<script>
import { localizedRoute } from '@vue-storefront/core/lib/multistore';
import { mapActions, mapGetters } from 'vuex';
import DownArrow from '../main-footer/DownArrow';

export default {
  name: 'NavBarLink',
  props: {
    menuItem: {
      type: Object,
      required: true
    }
  },
  components: { DownArrow },
  computed: {
    ...mapGetters('craftCms', ['getActiveMenuState', 'levelTwoByParent']),
    isActive() {
      if (!this.hasChildren) return false;
      return !!(
        this.getActiveMenuState && this.getActiveMenuState === this.menuItem.id
      );
    },
    showArrow() {
      const check = this.menuItem.id;
      return check === 'available-now' || check === 'my-ribble' ? false : true;
    },
    hasChildren() {
      const children = this.levelTwoByParent(this.menuItem.id);
      return !!children.length;
    },
    menuLinkCss() {
      if (
        this.menuItem.ctaLink &&
        !this.menuItem.blockBackgroundColour &&
        !this.menuItem.blockTextColour
      ) {
        return this.isActive
          ? 'bg-white text-blue-500'
          : 'bg-blue-500 text-white hover:bg-white hover:text-blue-500';
      }
      return 'hover:text-blue-500';
    },
    menuLinkStyles() {
      if (
        this.menuItem.ctaLink &&
        this.menuItem.blockBackgroundColour &&
        this.menuItem.blockTextColour
      ) {
        return this.isActive
          ? `backgroundColor: #FFF; color: ${this.menuItem.blockBackgroundColour} !important;`
          : `backgroundColor: ${this.menuItem.blockBackgroundColour}; color: ${this.menuItem.blockTextColour};`;
      }
      return '';
    },
    menuLink() {
      return this.menuItem && this.menuItem.headingUrl
        ? this.menuItem.headingUrl
        : null;
    }
  },
  methods: {
    ...mapActions({
      openMenu: 'craftCms/openMenu',
      closeMenu: 'craftCms/closeMenu'
    })
  }
};
</script>

<style scoped>
/* remove from at the end of enter for Vue2 */
.fadeThree-enter-from,
.fadeThree-leave-to {
  opacity: 0;
}
/* remove from at the end of leave for Vue2 */
.fadeThree-leave-from,
.fadeThree-enter-to {
  opacity: 1;
}

.fadeThree-enter-active,
.fadeThree-leave-active {
  transition: 600ms;
}
</style>
