import { currentStoreView } from '@vue-storefront/core/lib/multistore';

export default {
  data () {
    return {
      userCountry: '',
      userCurrency: ''
    }
  },
  computed: {
    allowSpecificC2W () {
      return this.$store.getters['core_config/getConfigItem']('ribble_ctw/general/specificcountry');
    },
    specificCountryC2W () {
      const countryList = this.$store.getters['core_config/getConfigItem']('ribble_ctw/general/specificcountry');
      return countryList || [];
    },
    allowSpecificFinance () {
      return this.$store.getters['core_config/getConfigItem']('payment/vfinance/allowspecific');
    },
    specificCountryFinance () {
      const countryList = this.$store.getters['core_config/getConfigItem']('payment/vfinance/specificcountry');
      return countryList || '';
    },
    showC2WForCountry () {
      if (this.userCurrency !== 'GBP') return false;
      if (!this.allowSpecificC2W) return true;
      return this.specificCountryC2W.split(',').some(country => country === this.userCountry);
    },
    showFinanceForCountry () {
      if (this.userCurrency !== 'GBP') return false;
      if (!this.allowSpecificFinance) return true;
      return this.specificCountryFinance.split(',').some(country => country === this.userCountry);
    },
    storeView () {
      return currentStoreView();
    }
  },
  mounted () {
    this.userCountry = this.$store.getters['ayko_localisation/getCountry'];
    this.userCurrency = this.$store.getters['ayko_localisation/getCurrency'];
  }
}
