<template>
  <div>
    <form class="flex items-center gap-1">
      <label>Qty</label>
      <input
        type="number"
        :value="quantity"
        inputmode="numeric"
        pattern="[0-9]*"
        class="w-12 border-0 p-1"
        @change="handleChange($event.target.value)"
      />
    </form>
    <CartTrack
      v-if="!getLoadingCart && prevQuantity !== 0 && action"
      :product="item.product"
      :action="action"
      btnText="Qty"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CartTrack from 'theme/components/theme/bikebuilder/bloomreach/CartTrack';

export default {
  name: 'CartMenuQty',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  components: { CartTrack },
  data() {
    return {
      quantity: 0,
      prevQuantity: 0
    };
  },
  computed: {
    ...mapGetters('graphqlCart', ['getLoadingCart']),
    action() {
      let action = null;
      if (this.prevQuantity > this.quantity) {
        action = 'remove';
      }
      if (this.prevQuantity < this.quantity) {
        action = 'add';
      }
      return action;
    },
    payload() {
      return {
        cartItemUid: this.item.uid,
        quantity: this.quantity
      };
    }
  },
  methods: {
    ...mapActions('graphqlCart', ['updateCartItems']),
    handleChange(value) {
      this.prevQuantity = this.quantity;
      this.quantity = parseInt(value);
      this.updateCartItems(this.payload);
    }
  },
  created() {
    this.quantity = this.item.quantity;
  }
};
</script>
