<template>
  <nav class="bg-black border-t border-gray-600 px-8 py-2">
    <ul class="flex items-center space-x-8">
      <li v-for="link in menuLinks" :key="link.slug" class="text-sm">
        <a :href="link.slug" class="text-white hover:text-white focus:text-white uppercase font-featured font-bold">
          {{ link.linkText }}
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'KioskCategoryMenu',
  data () {
    return {
      menuLinks: [
        {
          linkText: 'Road',
          slug: '/bikes/road-bikes'
        },
        {
          linkText: 'Electric',
          slug: '/bikes/electric-bikes'
        },
        {
          linkText: 'Hybrid',
          slug: '/bikes/hybrid-bikes'
        },
        {
          linkText: 'Gravel',
          slug: '/bikes/gravel-bikes'
        },
        {
          linkText: 'Off-Road',
          slug: '/bikes/off-road-bikes'
        },
        {
          linkText: 'TT&TRI',
          slug: '/bikes/time-trial-triathlon-bikes'
        },
        {
          linkText: 'Bikes',
          slug: '/bikes'
        }
      ]
    }
  }
}
</script>
