<template>
  <article class="p-6">
    <h3 class="uppercase m-0 text-base font-bold">
      <router-link
        :to="localizedRoute(menuData.headingUrl ? menuData.headingUrl : '')"
        class="flex items-center space-x-2"
        @click.native="closeMenu"
      >
        <span>
          {{ menuData.title }}
        </span>
        <RightArrow class="h-5" />
      </router-link>
    </h3>
    <p class="my-2 text-sm italic text-gray-500">
      {{ menuData.description }}
    </p>
    <ul>
      <li
        v-for="link, index in getLevelTwoMenu"
        :key="`${menuData.headingUrl}-${link.pageUrl}-${index}`"
        class="px-2 py-4 lg:px-0 lg:py-1 text-sm border-b last:border-b-0 lg:border-none"
      >
        <a v-if="link.externalUrl" :href="link.pageUrl" @click.native="closeMenu">
          {{ link.title }}
        </a>
        <router-link v-else :to="link.pageUrl ? localizedRoute(link.pageUrl) : ''" @click.native="closeMenu">
          {{ link.title }}
        </router-link>
      </li>
    </ul>
  </article>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import RightArrow from '../ui-icons/RightArrow';
import { localizedRoute } from '@vue-storefront/core/lib/multistore';

export default {
  name: 'LevelTwoMenu',
  props: {
    menuData: {
      type: Object,
      required: true
    }
  },
  components: { RightArrow },
  computed: {
    ...mapGetters('craftCms', ['levelThreeMainMenu']),
    getLevelTwoMenu () {
      return this.levelThreeMainMenu(this.menuData.id);
    }
  },
  methods: {
    ...mapActions({
      closeMenu: 'craftCms/closeMenu'
    })
  }
};
</script>
