<template>
  <Transition name="fade">
    <div
      v-if="getCartBikeCount > 1"
      class="my-5 text-red-500 font-featured uppercase p-5 border border-red-500 text-center"
    >
      <div class="font-bold font-featured text-base">
        {{ $t('Action Required') }}!
      </div>
      <div>
        {{
          $t(
            'To ensure timely delivery and the best service, we limit orders to one bike per checkout. Thank you for your understanding.'
          )
        }}
      </div>
    </div>
  </Transition>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BikeCartWarning',
  computed: {
    ...mapGetters('graphqlCart', ['getCartBikeCount'])
  }
};
</script>
