<template>
  <transition name="fade">
    <div
      class="fixed w-screen h-screen left-0 top-0"
      id="modal-bg"
      @click="close"
    >
      <article
        class="absolute bg-white left-0 right-0 shadow-md m-auto w-screen md:w-3/4 lg:w-1/2 max-w-[500px]"
        :class="height"
        @click="close"
      >
        <div @click.stop>
          <header class="relative">
            <slot name="heading" />
            <button
              type="button"
              class="absolute top-2 right-0"
              @click="$emit('close')"
            >
              <span
                class="lowercase border-b-black border-b-[1px] pl-2 pr-2 mr-3 text-[12px]"
              >
                {{ $t('Close') }}
              </span>
            </button>
          </header>
          <div class="p-5 overflow-auto modal-body">
            <slot name="body" />
          </div>
        </div>
      </article>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'BaseModal',
  methods: {
    close: function () {
      this.$emit('close');
    }
  },
  props: {
    height: {
      type: String,
      default: 'h-auto'
    }
  }
};
</script>

<style scoped>
#modal-bg {
  z-index: 9997;
  background-color: rgba(0, 0, 0, 0.4);
}
article {
  top: 50%;
  transform: translateY(-50%);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.modal-body {
  height: calc(100% - 4.25rem);
}
</style>
