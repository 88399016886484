<template>
  <client-only>
    <div>
      <go-in-store :key="`gis-${$route.fullPath}`" />
      <fresh-chat :key="`fresh-chat-${$route.fullPath}`"/>
    </div>
  </client-only>
</template>
<script>
import ClientOnly from 'vue-client-only';
import FreshChat from 'theme/components/theme/blocks/LiveChat/FreshChat';
import GoInStore from 'theme/components/theme/blocks/LiveChat/GoInStore';
import {isModuleRegistered, registerModule} from '@vue-storefront/core/lib/modules';
import {CoreConfigModule} from '../../../../../../modules/core-config';

export default {
  name: 'LiveChat',
  components: {
    FreshChat,
    GoInStore,
    ClientOnly
  },
  serverPrefetch () {
    return this.$store.dispatch('core_config/list', {})
  },
  async beforeMount () {
    await this.$store.dispatch('core_config/list', {});
  },
  beforeCreate () {
    if (!isModuleRegistered('core_config')) {
      registerModule(CoreConfigModule);
    }
  }
}
</script>
