<template>
  <modal class="brexit-popup" name="modal-brexitPopup">
    <div slot="content">
      <div class="u-position--relative u-width--full">
        <button type="button" @click="close" class="rc-close-btn">
          <Cross class="rc-close" />
        </button>
      </div>
      <div class="brexit-content">
        <cms-block :identifier="'brexit-info'" :style-content="true" />
      </div>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex';
import Modal from 'theme/components/core/Modal';
import CmsBlock from 'theme/components/core/blocks/Cms/Block';
import { CoreConfigModule } from '../../../../modules/core-config';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import Cross from '../../../ribble/components/theme/bikebuilder/svg/ui/Cross';
import { isModuleRegistered, registerModule } from '@vue-storefront/core/lib/modules';

export default {
  name: 'BrexitNotification',
  components: {
    Modal,
    Cross,
    CmsBlock
  },
  computed: {
    ...mapGetters('core_config', ['getConfigItem']),
    currentCountryCode () {
      return this.$store.getters['ayko_localisation/getCountry'];
    },
    euCountries () {
      return this.getConfigItem('general/country/eu_countries');
    }
  },
  methods: {
    close () {
      this.$store.dispatch('brexit/set', {brexitCode: 'brexitAccepted', value: true}).then(() => {
        this.$bus.$emit('modal-hide', 'modal-brexitPopup');
      });
    },
    checkCountry (countryCode) {
      return this.euCountries && this.euCountries.split(',').includes(countryCode);
    },
    showBrexitModal () {
      const isEuCountry = this.checkCountry(this.currentCountryCode);
      if (!isEuCountry) return;
      this.$store.dispatch('brexit/check', { brexitCode: 'brexitAccepted' }).then((brexitClaim) => {
        if (!brexitClaim) {
          this.$bus.$emit('modal-show', 'modal-brexitPopup');
          this.$store.dispatch('brexit/set', { brexitCode: 'brexitAccepted', value: false });
          return;
        }
        if (brexitClaim.value === false) {
          this.$bus.$emit('modal-show', 'modal-brexitPopup');
        }
      });
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.showBrexitModal();
    });
  },
  async beforeMount () {
    await this.$store.dispatch('core_config/list', {});
  },
  beforeCreate () {
    if (!isModuleRegistered('core_config')) {
      registerModule(CoreConfigModule);
    }
  }
}
</script>

<style scoped>
.rc-close {
  height: 18px;
  width: 18px;
}
.rc-close-btn {
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}
.brexit-content{
  padding-top: 50px;
}
</style>
