// https://github.com/daliborgogic/nuxt-interpolation

import Vue from 'vue'
import { localizedRoute } from '@vue-storefront/core/lib/multistore'
import { normalizeUrlPath } from '@vue-storefront/core/modules/url/helpers'

export default Vue.directive('interpolation', {
  // Called only once, when the directive is first bound to the element. This is where you can do one-time setup work.
  bind (el, binding, vnode) {
    const navigate = event => {
      if (event.target.nodeName.toLowerCase() !== 'a') {
        return;
      }
      const normalizedPath = normalizeUrlPath(event.currentTarget.getAttribute('href'))
      if (normalizedPath && normalizedPath[0] === '/') {
        event.preventDefault()
        const dispatchPath = (normalizedPath.endsWith('/') ? normalizedPath.slice(0, -1) : normalizedPath)
        const match = vnode.context.$router.match(normalizedPath)
        if (match && match.name !== 'page-not-found') {
          vnode.context.$router.push(match)
        } else {
          vnode.context.$router.push(localizedRoute({
            path: normalizedPath,
            name: `urldispatcher-${dispatchPath}`,
            pathToRegexpOptions: { strict: true }
          }), r => null, r => null)
        }
      }
    }

    let links = el.getElementsByTagName('a')

    const addListeners = links => {
      for (let i = 0; i < links.length; i++) {
        const path = links[i].getAttribute('target')
        // For improved security `rel="noopener"` will be added automatically if target is `_blank`
        // https://github.com/mathiasbynens/rel-noopener/
        if (path && path === '_blank') {
          links[i].setAttribute('rel', 'noopener')
        }
        links[i].addEventListener('click', navigate, false)
      }
    }

    const removeListeners = links => {
      for (let i = 0; i < links.length; i++) {
        links[i].removeEventListener('click', navigate, false)
      }
      links = []
    }

    addListeners(links)

    el.$componentUpdated = () => {
      removeListeners(links)
      Vue.nextTick(() => addListeners(links))
    }

    el.$destroy = () => el.removeEventListener('click', removeListeners(links))
  },

  // Called after the containing component’s VNode and the VNodes of its children have updated.
  componentUpdated: el => el.$componentUpdated(),

  // Called only once, when the directive is unbound from the element.
  unbind: el => el.$destroy()
})
