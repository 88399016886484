<template>
  <form
    @submit.prevent="search"
    style="height: 68px"
    class="bg-ribble text-white absolute z-50 top-0 w-full flex justify-between"
  >
    <input
      type="search"
      ref="search"
      v-model="term"
      :placeholder="$t('Search...')"
      class="flex-grow bg-ribble px-5 py-8 placeholder-white focus:outline-none border-0 text-white"
    />
    <button
      type="submit"
      @click="toggleSearch"
      class="p-5 text-white transition ease-in-out duration-300 focus:outline-none focus:text-black hover:text-black"
    >
      <CloseSvg class="text-inherit h-5 w-5" />
    </button>
  </form>
</template>

<script>
import { mapActions } from 'vuex';
import CloseSvg from './CloseSvg';

export default {
  name: 'SearchBar',
  components: { CloseSvg },
  data() {
    return {
      term: ''
    };
  },
  methods: {
    ...mapActions('craftCms', ['toggleSearch']),
    search() {
      if (!this.term) return;
      this.$router
        .push({ name: 'search', query: { term: this.term } })
        .catch((err) => {});
    }
  },
  mounted() {
    this.$refs.search.focus();
  }
};
</script>

<style scoped>
.rc-z {
  z-index: 501;
}
</style>
