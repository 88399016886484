<template>
  <div
    class="rc-container mx-auto rc-hidden w-full absolute left-0 right-0 shadow-md rc-z bg-white max-w-screen-3xl"
    @mouseleave="closeMenu"
  >
    <LevelOneMenu @mouseover.native="openMenu(activeLevelOneData.id)" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import LevelOneMenu from './LevelOneMenu';

export default {
  name: 'MegaMenu',
  components: { LevelOneMenu },
  computed: {
    ...mapGetters('craftCms', [
      'getActiveMenuState',
      'activeLevelOneData',
      'levelOneData'
    ]),
    trendingSkus() {
      const trendingData = this.levelOneData.map((data) => data.trending);
      const trending = trendingData.filter((x) => x.length);
      return trending.map((x) => x[0].sku);
    }
  },
  methods: {
    ...mapActions({
      openMenu: 'craftCms/openMenu',
      closeMenu: 'craftCms/closeMenu'
    })
  }
};
</script>

<style scoped>
.rc-z {
  z-index: 1000;
}
.rc-hidden {
  display: none;
}
@media (min-width: 1024px) {
  .rc-hidden {
    display: block;
  }
}
</style>
