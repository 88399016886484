<template>
  <div>
    <div class="border-b border-t py-5">
      <ClientOnly>
        <Newsletter v-show="!isCheckoutPage" />
      </ClientOnly>
    </div>
    <DesktopFooter />
    <MobileFooter />
  </div>
</template>

<script>
import Newsletter from './Newsletter';
import ClientOnly from 'vue-client-only';
import MobileFooter from './MobileFooter';
import DesktopFooter from './DesktopFooter';
import CurrentPage from 'theme/mixins/currentPage';
import ContentJS from 'theme/components/theme/blocks/CmsBlock/ContentJS';

export default {
  name: 'RibbleFooter',
  components: {
    MobileFooter,
    DesktopFooter,
    Newsletter,
    ClientOnly
  },
  mixins: [CurrentPage, ContentJS]
};
</script>
