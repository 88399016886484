<template>
  <div class="my-5">
    <div class="my-5 flex justify-between items-center border-b border-dashed">
      <h3 class="m-0 text-base">Applied Coupon</h3>
      <div class="flex items-center gap-1">
        <span v-for="coupon in coupons" :key="coupon">{{ coupon }}</span>
        <button type="button" @click="removeCouponCode" class="text-red-500">
          <CircleCrossIcon />
        </button>
      </div>
    </div>
    <div class="my-5 flex justify-between items-center border-b border-dashed">
      <h3 class="m-0 text-base">
        {{ $t('Discount') }}
      </h3>
      <span>{{ getAppliedDiscount }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CircleCrossIcon from './icons/CircleCrossIcon.vue';

export default {
  name: 'AppliedCoupons',
  components: {
    CircleCrossIcon
  },
  computed: {
    ...mapGetters('graphqlCart', ['getAppliedCoupons', 'getAppliedDiscount']),
    coupons() {
      return this.getAppliedCoupons.map((coupon) => coupon.code);
    }
  },
  methods: {
    ...mapActions('graphqlCart', ['removeCouponCode'])
  }
};
</script>
