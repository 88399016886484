<template>
  <div class="relative z-10">
    <nav
      class="rc-hidden border-t border-gray-500/40 bg-black"
      :class="isHome ? 'bg-opacity-80' : null"
    >
      <div
        id="navDiv"
        class="mx-auto max-w-screen-3xl"
        @mouseleave="relatedCloseMenu"
      >
        <ul id="navUl" class="flex items-center justify-between">
          <li v-for="menuItem in levelOneData" class="flex-grow">
            <NavBarLink :menu-item="menuItem" />
          </li>
        </ul>
      </div>
    </nav>
    <MegaMenu />
    <MessageBar />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import MessageBar from './MessageBar';
import NavBarLink from './NavBarLink';
import MegaMenu from './MegaMenu';

export default {
  name: 'NavBar',
  components: { NavBarLink, MessageBar, MegaMenu },
  computed: {
    ...mapGetters('craftCms', ['levelOneData', 'getActiveMenuState']),
    isHome() {
      return this.$route.name === 'home';
    }
  },
  methods: {
    ...mapActions({
      closeMenu: 'craftCms/closeMenu'
    }),
    relatedCloseMenu(event) {
      if (event.relatedTarget === null) {
        return;
      }
      if (event.relatedTarget.tagName === 'NAV') {
        this.closeMenu();
      }
    }
  }
};
</script>

<style scoped>
.rc-hidden {
  display: none;
}
@media (min-width: 1024px) {
  .rc-hidden {
    display: block;
  }
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.fade-enter-active {
  transition: opacity 200ms ease;
}
.fade-leave-active {
  transition: opacity 600ms ease;
}
</style>
