<template>
  <article class="p-5 px-10 border">
    <header class="flex items-center justify-between my-5">
      <h2 class="m-0 font-featured text-2xl font-bold uppercase">
        {{ $t('Register') }}
      </h2>
      <button type="button" @click="closeModal">
        <CloseIcon />
      </button>
    </header>
    <form @submit.prevent="handleSubmit" class="mb-5">
      <div class="mb-5">
        <label for="email" class="sr-only">Email</label>
        <input
          type="email"
          id="email"
          v-model="email"
          placeholder="Email address *"
          class="w-full border p-2"
          required
        />
        <Transition name="fade">
          <p v-if="showEmailMessage" class="text-xs my-1 mx-1 text-gray-500">
            {{ $t('Field is required') }}
          </p>
        </Transition>
      </div>
      <div class="mb-5 grid grid-cols-2 gap-5">
        <div>
          <label for="first-name" class="sr-only">First Name</label>
          <input
            type="text"
            id="first-name"
            v-model="firstName"
            placeholder="First name *"
            class="w-full border p-2"
            required
          />
          <Transition name="fade">
            <p v-if="firstNameMessage" class="text-xs my-1 mx-1 text-gray-500">
              {{ $t('Field is required') }}
            </p>
          </Transition>
        </div>
        <div>
          <label for="first-name" class="sr-only">Last Name</label>
          <input
            type="text"
            id="last-name"
            v-model="lastName"
            placeholder="Last name *"
            class="w-full border p-2"
            required
          />
          <Transition name="fade">
            <p v-if="lastNameMessage" class="text-xs my-1 mx-1 text-gray-500">
              {{ $t('Field is required') }}
            </p>
          </Transition>
        </div>
      </div>
      <div class="mb-5">
        <label for="password" class="sr-only">Password</label>
        <input
          type="password"
          id="password"
          v-model="password"
          placeholder="Password *"
          class="w-full border p-2"
          required
        />
        <p v-if="showPasswordMessage" class="text-xs my-1 mx-1 text-gray-500">
          {{ $t('Field is required') }}
        </p>
      </div>
      <div class="mb-5">
        <label for="password" class="sr-only">Repeat Password</label>
        <input
          type="password"
          id="repeat-password"
          v-model="repeatPassword"
          placeholder="Repeat Password *"
          class="w-full border p-2"
          required
        />
        <p
          v-if="showPasswordConfirmMessage"
          class="text-xs my-1 mx-1 text-gray-500"
        >
          {{ $t('Field is required & must match password') }}
        </p>
      </div>
      <button
        type="submit"
        class="border border-black bg-black text-white uppercase text-center p-3 w-full font-featured flex items-center justify-center gap-1 hover:bg-gray-900"
      >
        <span class="leading-none">{{ $t('Register an account') }}</span>
        <ArrowRightIcon />
      </button>
      <div class="my-5">
        <button type="button" @click="goToLogin">
          {{ $t('or login to your account') }}
        </button>
      </div>
      <Transition name="fade">
        <p v-if="getLoadingAccount" class="my-5 text-center">Loading...</p>
      </Transition>
      <Transition name="fade">
        <p v-if="getAccountErrors" class="my-5 text-center text-red-500">
          {{ getAccountErrors }}
        </p>
      </Transition>
    </form>
  </article>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CloseIcon from 'theme/components/theme/bikebuilder/graphql-auth/icons/CloseIcon.vue';
import ArrowRightIcon from 'theme/components/theme/bikebuilder/graphql-auth/icons/ArrowRightIcon.vue';

export default {
  name: 'RibbleRegister',
  components: {
    CloseIcon,
    ArrowRightIcon
  },
  data() {
    return {
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      repeatPassword: ''
    };
  },
  computed: {
    ...mapGetters('graphqlAccount', ['getLoadingAccount', 'getAccountErrors']),
    firstNameMessage() {
      return !!(this.firstName === '');
    },
    lastNameMessage() {
      return !!(this.lastName === '');
    },
    showEmailMessage() {
      return !!(this.email === '');
    },
    showPasswordMessage() {
      return !!(this.password === '');
    },
    showPasswordConfirmMessage() {
      return !!(
        this.password !== this.repeatPassword || this.repeatPassword === ''
      );
    }
  },
  methods: {
    ...mapActions('graphqlAccount', ['createCustomer']),
    closeModal() {
      this.$bus.$emit('modal-hide', 'modal-signup');
    },
    goToLogin() {
      this.$store.commit('ui/setAuthElem', 'login');
    },
    bloomreachTrack(email, action) {
      window.exponea.track('consent', {
        email: email,
        email_id: email,
        action: action,
        category: 'newsletter',
        valid_until: 'unlimited',
        message: 'This consent was tracked from website registration' // additional attribute
      });
    },
    async handleSubmit() {
      const payload = {
        email: this.email,
        firstname: this.firstName,
        lastname: this.lastName,
        password: this.password
      };
      await this.createCustomer(payload).then(() => {
        if (!this.getLoadingAccount && !this.getAccountErrors) {
          window.exponea.identify({ email_id: this.email });
          window.exponea.track('registration', {
            email: this.email,
            first_name: this.firstName,
            last_name: this.lastName
          });
          this.bloomreachTrack(this.email, 'accept');
          this.email = '';
          this.firstname = '';
          this.lastname = '';
          this.password = '';
          this.$bus.$emit('modal-hide', 'modal-signup');
        }
      });
    }
  }
};
</script>
