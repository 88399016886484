<template>
  <button
    type="button"
    @click="toggleCart"
    class="uppercase tracking-wider flex gap-1 px-3 text-white"
  >
    <CartIcon />
    <span>{{ getCartItemsCount }}</span>
    <template v-if="getCartItemsCount > 0">
      <span class="rc-hidden">-</span>
      <span class="rc-hidden">
        {{ getCartTotal }}
      </span>
    </template>
  </button>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CartIcon from './icons/CartIcon.vue';

export default {
  name: 'CartBtn',
  components: {
    CartIcon
  },
  computed: {
    ...mapGetters('graphqlCart', ['getCartTotal', 'getCartItemsCount'])
  },
  methods: {
    ...mapActions('graphqlCart', ['toggleCart', 'fetchCart'])
  },
  mounted() {
    this.fetchCart();
    // run this.fetchCart() after 3 seconds to ensure the cart is loaded
    setTimeout(() => {
      this.fetchCart();
    }, 3000);
  }
};
</script>

<style scoped>
.rc-hidden {
  display: none;
}
@media (min-width: 1024px) {
  .rc-hidden {
    display: block;
  }
}
</style>
