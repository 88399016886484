<template>
  <div v-if="product && product.final_price_incl_tax" class="product__price">
    {{ label }}
    <span><span class="price notranslate">{{ localisePrice(product.final_price_incl_tax, storeView) }}</span></span>
  </div>
</template>
<script>
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import i18n from '@vue-storefront/i18n'
import {mapGetters} from 'vuex'

export default {
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      localisePrice: 'ayko_localisation/localisePrice'
    }),
    storeView () {
      return currentStoreView()
    },
    label () {
      return i18n.t('From: ')
    }
  }
}
</script>
