<template>
  <div class="flex justify-end">
    <Transition name="fade" mode="out-in">
      <form
        v-if="showDiscountCodeForm || couponCode"
        @submit.prevent="handleSubmit"
        class="flex items-center w-full"
      >
        <div class="flex items-center gap-2 w-1/2">
          <label for="coupon-code" class="sr-only">
            {{ $t('Discount Code') }}
          </label>
          <input
            v-model="couponCode"
            type="text"
            id="coupon-code"
            class="border px-1 h-12 grow"
          />
        </div>
        <div class="w-1/2">
          <button
            type="submit"
            class="flex items-center justify-center gap-1 px-2 border bg-blue-500 border-blue-500 text-white uppercase text-center font-featured h-12 w-full transition hover:bg-blue-400"
          >
            <TicketIcon />
           <span>{{ $t('Apply Discount') }}</span>
          </button>
        </div>
      </form>
      <button v-else @click="showDiscountCodeForm = true" class="hover:underline">
        {{ $t('Have a discount code?') }}
      </button>
    </Transition>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import TicketIcon from './icons/TicketIcon.vue';

export default {
  name: 'CouponCodeForm',
  components: { TicketIcon },
  data() {
    return {
      couponCode: '',
      showDiscountCodeForm: false
    };
  },
  methods: {
    ...mapActions('graphqlCart', ['applyCouponCode']),
    handleSubmit() {
      this.applyCouponCode(this.couponCode).then(() => {
        this.couponCode = '';
      });
    }
  }
};
</script>
