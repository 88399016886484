<template>
  <article class="p-5">
    <header>
      <h3
        v-if="activeTrending.trendingLabel"
        class="uppercase m-0 text-lg font-bold"
      >
        <template v-if="activeTrending.trendingLabel === 'trending'">
          {{ $t('Most Trending') }}
        </template>
        <template v-if="activeTrending.trendingLabel === 'popular'">
          {{ $t('Our Most Popular') }}
        </template>
      </h3>
      <h4 class="uppercase m-0 text-base font-bold text-blue-500">
        {{ activeTrending.title }}
      </h4>
    </header>
    <div
      v-if="activeTrending.productImage.length"
      :class="gridCount < 3 ? 'p-5' : 'p-1'"
    >
      <router-link
        v-if="activeTrending.pageUrl"
        :to="localizedRoute(activeTrending.pageUrl)"
        @click.native="closeMenu"
      >
        <img :src="activeTrending.productImage[0].url" alt="" height="256" />
      </router-link>
    </div>
    <h4 v-if="activeTrending.pageUrl" class="uppercase m-0 text-base font-bold">
      <router-link
        :to="localizedRoute(activeTrending.pageUrl)"
        @click.native="closeMenu"
      >
        {{ activeTrending.heading }}
      </router-link>
    </h4>
    <p v-if="activeTrending.summary" class="my-2 text-sm text-gray-500">
      {{ activeTrending.summary }}
    </p>
    <footer v-if="!priceError && showPrices">
      <div v-if="showFromPrice" class="mb-1 font-featured font-bold">
        <span>From:</span>
        {{ localisePrice(getTrendingFinalPrice, storeView) }}
      </div>
      <template v-else>
        <p
          v-if="getTrendingSpecialPrice && saving"
          class="mb-1 font-featured font-bold"
        >
          {{ localisePrice(getTrendingFinalPrice, storeView) }}
        </p>
        <div v-if="getTrendingSpecialPrice && saving" class="text-sm">
          <p class="leading-none mb-1 font-featured">
            Was {{ localisePrice(getTrendingOriginalPrice, storeView) }}
          </p>
          <p class="leading-none text-red-500 font-featured font-bold">
            Save {{ localisePrice(saving, storeView) }}
          </p>
        </div>
      </template>
    </footer>
  </article>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { localizedRoute } from '@vue-storefront/core/lib/multistore';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';

export default {
  name: 'Trending',
  props: {
    gridCount: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      showPrices: false
    };
  },
  computed: {
    ...mapGetters('trending', [
      'getTrendingPrice',
      'getTrendingFinalPrice',
      'getTrendingSpecialPrice',
      'getTrendingOriginalPrice'
    ]),
    ...mapGetters('craftCms', ['activeTrending']),
    ...mapGetters('ayko_localisation', ['localisePrice']),
    storeView() {
      return currentStoreView();
    },
    priceError() {
      return !this.getTrendingFinalPrice && !this.getTrendingOriginalPrice;
    },
    showFromPrice() {
      if (this.saving) return false;
      return (
        (!this.getTrendingSpecialPrice && this.getTrendingFinalPrice) ||
        this.getTrendingSpecialPrice === this.getTrendingFinalPrice
      );
    },
    saving() {
      if (!this.getTrendingSpecialPrice || !this.getTrendingOriginalPrice)
        return 0;
      return this.getTrendingOriginalPrice - this.getTrendingSpecialPrice;
    }
  },
  methods: {
    ...mapActions({
      closeMenu: 'craftCms/closeMenu'
    })
  }
};
</script>
