import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('ayko_localisation', ['getCountry']),
    ...mapGetters('attribute', ['getAttributeListByCode']),
    ...mapGetters('attribute', ['getAttributeListByCode']),
    warningStyles () {
      return 'color: red; font-size: small; margin-top: 5px; margin-bottom: 5px; font-weight: bold;';
    },
    shippingMessage () {
      return this.$t('Please note we can not ship this product to your selected country');
    },
    bannedShippingCountryAttrs () {
      return this.getAttributeListByCode['banned_shipping_countries'] || [];
    },
    productBannedShippingCodes () {
      // requires a product in the component that this is used in
      const product = this.product || this.getCurrentProduct;
      if (!product) return [];
      const productBannedCountries = product.banned_shipping_countries;
      if (!productBannedCountries || !productBannedCountries.length) return [];
      return product.banned_shipping_countries.map(code => {
        const option = this.bannedShippingCountryAttrs.options.find(attr => parseInt(attr.value) === parseInt(code));
        return option ? option.label : null;
      });
    },
    canShipToCountry () {
      // requires a product in the component that this is used in
      const isBanned = this.productBannedShippingCodes.includes(this.getCountry);
      return !isBanned;
    }
  },
  methods: {
    checkProductForBannedShipping (product) {
      // comma separated list of banned shipping country ids
      if (!product.banned_shipping_countries) return false;
      const bannedShippingIds = product.banned_shipping_countries.split(',');
      const productBannedShippingCodes = bannedShippingIds.map(id => {
        const option = this.bannedShippingCountryAttrs.options.find(attr => parseInt(attr.value) === parseInt(id));
        return option ? option.label : null;
      });
      return productBannedShippingCodes.includes(this.getCountry);
    },
    checkProductsForBannedShipping (products) {
      const bannedProducts = [];
      const productsToCheck = products.forEach(product => {
        if (this.checkProductForBannedShipping(product)) {
          bannedProducts.push(product);
        };
      });
      return !!bannedProducts.length;
    }
  }
}
