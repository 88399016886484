<template>
  <div>
    <button
      v-if="hasChildren"
      type="button"
      @click="toggleMenu(levelOneMenu.id)"
      class="uppercase font-bold text-md flex justify-between items-center w-full p-3"
      :class="
        getActiveMenuState === levelOneMenu.id ? 'bg-gray-200' : 'bg-white'
      "
    >
      <span>
        {{ levelOneMenu.title }}
      </span>
      <div>
        <DownArrow class="h-5" :class="isActive ? 'rotate-180' : 'rotate-0'" />
      </div>
    </button>
    <button
      v-else
      type="button"
      @click="navigatePage(levelOneMenu.headingUrl)"
      class="uppercase font-bold text-md flex justify-between items-center w-full p-3"
      :class="
        getActiveMenuState === levelOneMenu.id ? 'bg-gray-200' : 'bg-white'
      "
    >
      {{ levelOneMenu.title }}
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DownArrow from '../ui-icons/DownArrow';
import { localizedRoute } from '@vue-storefront/core/lib/multistore';

export default {
  name: 'MobileMenuLink',
  components: { DownArrow },
  props: {
    levelOneMenu: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('craftCms', [
      'activeLevelOneData',
      'levelTwoByParent',
      'getActiveMenuState'
    ]),
    hasChildren() {
      const children = this.levelTwoByParent(this.levelOneMenu.id);
      return !!children.length;
    },
    isActive() {
      return !!(
        this.getActiveMenuState &&
        this.getActiveMenuState === this.levelOneMenu.id
      );
    }
  },
  methods: {
    ...mapActions('craftCms', ['openMenu', 'closeMenu', 'closeMobileMenu']),
    toggleMenu(menuId) {
      if (menuId === this.getActiveMenuState) {
        this.closeMenu();
      } else {
        this.openMenu(menuId);
        this.$nextTick(() => {
          document.getElementById(`menu-${menuId}`).scrollIntoView();
        });
      }
    },
    navigatePage(url) {
      if (this.$route.path !== url) {
        this.$router.push(localizedRoute(url)).catch((err) => {});
      }
      this.closeMobileMenu();
    }
  }
};
</script>
