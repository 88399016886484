<template>
  <div class="w-100 bg-cl-th-secondary cl-tertiary promo-ribbon py10" v-if="data && data.content">
    <div class="container text-center" v-html="parsedContent" />
  </div>
</template>

<script>
import cmsBlock from 'vsf-cms-block-mixin/components/cmsBlock';

export default {
  name: 'PromoRibbon',
  mixins: [cmsBlock],
  props: {
    identifier: {
      type: String,
      default: 'promo_ribbon'
    }
  }
};
</script>

<style lang='scss' scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
@import '~theme/css/variables/typography';

.promo-ribbon /deep/ {
  p {
    margin: 0;
  }

  @media(max-width: 640px) {
    .container p *:first-child {
      display: none;
    }
  }

  a {
    font-weight: bold;
    font-family: map-get($font-families, secondary);
    color: map-get($colors, ribble-blue-primary);
    margin-left: .5em;

    &:after {
      content: "\F061";
      font-weight: normal;
      margin-left: .3em;
      font-family: FontAwesome;
      display: inline-block !important; // override display none on a after
    }
  }
}
</style>
