<template>
  <ul>
    <li v-for="section in fullSpecBySection" :key="section.label">
      <h4
        v-if="section.values.length"
        class="m-0 mb-2 uppercase font-bold font-featured text-lg"
      >
        {{ section.label }}
      </h4>
      <ul v-if="section.values.length" class="mb-5">
        <li v-for="item in section.values">
          {{ item }}
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'FullSpecList',
  props: {
    fullSpecBySection: {
      type: Array,
      required: true
    }
  }
};
</script>
