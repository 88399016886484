<template>
  <div @mouseenter="showAccountDropdown">
    <button type="button" @click="goToAccount" class="relative">
      <span>
        {{ isLoggedIn ? $t('My account') : $t('Register or Log In') }}
      </span>

      <Transition name="fade">
        <ul
          v-if="isLoggedIn && getShowAccountDropdown"
          @mouseleave="hideAccountDropdown"
          class="bg-black p-5 absolute -left-10 top-8 w-52 space-y-3 transition shadow-md shadow-white text-left border border-gray-600"
        >
          <li class="border-b mb-2 pb-4 border-gray-600">
            <button type="button" @click="toggleShowProfile">
              {{ $t('Profile') }}
            </button>
          </li>
          <li class="border-b mb-2 pb-4 border-gray-600">
            <button type="button" @click="toggleShowShipping">
              {{ $t('Shipping Details') }}
            </button>
          </li>
          <li class="border-b mb-2 pb-4 border-gray-600">
            <button type="button" @click="toggleShowNewsletter">
              {{ $t('Newsletter') }}
            </button>
          </li>
          <li class="border-b mb-2 pb-4 border-gray-600">
            <button type="button" @click="toggleShowOrders">
              {{ $t('Orders') }}
            </button>
          </li>
          <li>
            <button type="button" @click="logout">
              {{ $t('Logout') }}
            </button>
          </li>
        </ul>
      </Transition>
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus';

export default {
  computed: {
    ...mapGetters('graphqlAccount', ['isLoggedIn', 'getShowAccountDropdown'])
  },
  methods: {
    ...mapActions('graphqlAccount', [
      'logout',
      'clearError',
      'toggleShowOrders',
      'toggleShowProfile',
      'toggleShowShipping',
      'toggleShowNewsletter',
      'showAccountDropdown',
      'hideAccountDropdown'
    ]),
    goToAccount() {
      if (this.isLoggedIn) {
        this.showAccountDropdown();
      } else {
        EventBus.$emit('modal-show', 'modal-signup');
      }
    }
  }
};
</script>

<style scoped>
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transform-style: ease;
  transition-duration: 0.5s;
  transition-property: opacity;
}
</style>
