<script>
import i18n from '@vue-storefront/i18n'

export default {
  data () {
    return {
      chart: null
    }
  },

  watch: {
    $route () {
      this.$nextTick(() => this.hydrateCmsContent())
    }
  },

  mounted () {
    this.$nextTick(() => this.hydrateCmsContent())
  },

  methods: {
    hydrateCmsContent () {
      // Accordions
      let panels = document.querySelectorAll('.accordion + div');
      if (panels.length) {
        panels.forEach(panel => panel.previousElementSibling.addEventListener('click', e => {
          panels.forEach(panel => {
            panel.classList.remove('active');
            panel.previousElementSibling.classList.remove('active');
          });
          e.currentTarget.classList.add('active');
          e.currentTarget.nextElementSibling.classList.add('active');
        }));
      }

      // Highlight the day of the week
      let day = document.querySelector('.' + new Date().toLocaleString('en-gb', {weekday: 'short'}).toLowerCase());
      if (day) {
        day.classList.add('todaysDay')
      }

      // "Show more" buttons
      document.querySelectorAll('[data-role=showmore]').forEach(button => {
        let pane = button.nextElementSibling;
        pane.style.display = 'none';
        button.addEventListener('click', () => {
          pane.style.display = pane.style.display === 'none' ? 'block' : 'none';
          button.innerHTML = i18n.t(pane.style.display === 'none' ? 'Show More' : 'Show Less');
        })
      })

      // Trustpilot widget
      if (document.querySelector('.trustpilot-widget')) {
        // Check if Trustpilot is already loaded
        if (window && window.Trustpilot) {
          document.querySelectorAll('.trustpilot-widget').forEach(trustbox => {
            window.Trustpilot.loadFromElement(trustbox);
          })
        } else {
          // Mount script when a widget is first encountered
          let trustpilot = document.createElement('script');
          trustpilot.async = !0;
          trustpilot.setAttribute('src', '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js');
          document.head.appendChild(trustpilot);
        }
      }
    }
  }
}
</script>
<style lang="scss">
.accordion:not(.active) + div {
  display: none;
}
.accordion.active + div {
  display: block !important;
}
</style>
