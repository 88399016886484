<template>
  <footer class="bg-black text-white rc-hidden flex-col">
    <button
      v-for="menu in levelOneFooterMenu"
      :key="`level-one-${menu.id}`"
      @click="toggleFooter(menu.id)"
      class="text-left px-3"
    >
      <div class="flex flex-row justify-between items-center my-4">
        <span class="text-blue-500 font-bold text-lg">
          {{ menu.title }}
        </span>
        <DownArrow
          class="h-5 text-white"
          :class="activeFooterMenu === menu.id ? 'rotate-180' : 'rotate-0'"
        />
      </div>
      <div class="overflow-hidden border-b">
        <Transition name="dropdown">
          <ul v-if="activeFooterMenu === menu.id">
            <li
              v-for="link in getLevelTwoMenu(menu.id)"
              :key="`level-two-${link.id}`"
              class="mb-3"
            >
              <a
                v-if="link.externalUrl"
                :href="link.pageUrl"
                class="text-white hover:text-blue-500"
              >
                {{ link.title }}
              </a>
              <router-link
                :to="link.pageUrl ? localizedRoute(link.pageUrl) : ''"
                class="text-white hover:text-blue-500"
              >
                {{ link.title }}
              </router-link>
            </li>
          </ul>
        </Transition>
      </div>
    </button>
    <ul
      class="grid grid-rows-2 grid-cols-5 items-center justify-items-center gap-2 p-3 m-3"
    >
      <li
        v-for="link in footerSocialLinks"
        :key="`${link.id}-${link.linkIcon}`"
      >
        <a
          :href="link.linkUrl"
          target="_blank"
          class="hover:text-blue-500 flex flex-col text-xs items-center gap-3"
        >
          <SocialIcons :icon-data="link.linkIcon" />
          <span>
            {{ link.linkText }}
          </span>
        </a>
      </li>
      <li class="p-8 col-start-3 col-end-4">
        <RibbleLogo class="h-12" />
      </li>
    </ul>
  </footer>
</template>

<script>
import { localizedRoute } from '@vue-storefront/core/lib/multistore';
import { mapGetters, mapActions } from 'vuex';
import SocialIcons from './SocialIcons';
import RibbleLogo from './RibbleLogo';
import DownArrow from './DownArrow';

export default {
  name: 'MobileFooter',
  components: {
    SocialIcons,
    RibbleLogo,
    DownArrow
  },
  computed: {
    ...mapGetters('craftCms', [
      'levelOneFooterMenu',
      'levelTwoFooterMenu',
      'activeFooterMenu',
      'footerSocialLinks'
    ])
  },
  methods: {
    ...mapActions('craftCms', ['openFooterMenu', 'closeFooterMenu']),
    toggleFooter(menuId) {
      menuId === this.activeFooterMenu
        ? this.closeFooterMenu()
        : this.openFooterMenu(menuId);
    },
    getLevelTwoMenu(parentId) {
      return this.levelTwoFooterMenu(parentId);
    }
  }
};
</script>

<style scoped>
.rotate-180 {
  transform: rotate(180deg);
}
.rc-hidden {
  display: flex;
}
@media (min-width: 1024px) {
  .rc-hidden {
    display: none;
  }
}
.dropdown-enter-active {
  transition: all 0.4s ease;
}

.dropdown-leave-active {
  transition: all 0.01s;
}

.dropdown-enter {
  transform: translate(0, -100%);
}

.dropdown-leave-to {
  transform: translate(0, -100%);
}
</style>
