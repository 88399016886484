<template>
  <a href="#" @click="openChat" id="LiveChatOpen" v-if="showFreshChat">
    <div class="ab-livechat heading heading--five">
      <span>Live Chat</span>
      <svg
        aria-hidden="true"
        focusable="false"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          fill="currentColor"
          d="M144 208c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm112 0c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm112 0c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zM256 32C114.6 32 0 125.1 0 240c0 47.6 19.9 91.2 52.9 126.3C38 405.7 7 439.1 6.5 439.5c-6.6 7-8.4 17.2-4.6 26S14.4 480 24 480c61.5 0 110-25.7 139.1-46.3C192 442.8 223.2 448 256 448c141.4 0 256-93.1 256-208S397.4 32 256 32zm0 368c-26.7 0-53.1-4.1-78.4-12.1l-22.7-7.2-19.5 13.8c-14.3 10.1-33.9 21.4-57.5 29 7.3-12.1 14.4-25.7 19.9-40.2l10.6-28.1-20.6-21.8C69.7 314.1 48 282.2 48 240c0-88.2 93.3-160 208-160s208 71.8 208 160-93.3 160-208 160z"
        />
      </svg>
    </div>
  </a>
</template>

<script>
import config from 'config';
import { mapGetters, mapState } from 'vuex';
import { isServer } from '@vue-storefront/core/helpers';
import { Logger } from '@vue-storefront/core/lib/logger';
import { entityKeyName } from '@vue-storefront/core/lib/store/entities';
import { StorageManager } from '@vue-storefront/core/lib/storage-manager';

const cacheKey = entityKeyName('freshchat-open');
const cacheName = 'RibbleLiveChat';

export default {
  name: 'FreshChat',
  computed: {
    ...mapGetters({ getConfigItem: ['core_config/getConfigItem'] }),
    ...mapState({ currentUser: (state) => state.user.current }),
    isMyAccount() {
      const url = this.$route.path.split('/');
      return url.includes('my-account');
    },
    isConfigurator() {
      return this.$route.name === 'configurator';
    },
    showFreshChat() {
      const freshChatPages = [
        'urldispatcher-/cycle-schemes',
        'page-not-found',
        'urldispatcher-/help-centre'
      ];

      if (!this.isEnabled) return false;

      if (this.isConfigurator) return false;

      if (this.isMyAccount) return true;

      if (freshChatPages.includes(this.$route.name)) return true;

      return false;
    },
    isEnabled() {
      const config = this.getConfigItem('fresh_chat/general/is_enabled');
      return config && parseInt(config) === 1;
    }
  },
  methods: {
    async setFreshchatStatus(open) {
      const cache = await StorageManager.get(cacheName);
      cache.setItem(cacheKey, open, null, false).catch((err) => {
        Logger.error('Cannot store cache for ' + cacheKey, err)();
        if (
          err.name === 'QuotaExceededError' ||
          err.name === 'NS_ERROR_DOM_QUOTA_REACHED'
        ) {
          // quota exceeded error
          cache.clear(); // clear products cache if quota exceeded
        }
      });
    },
    async getFreshchatStatus() {
      const cache = await StorageManager.get(cacheName);
      return cache.getItem(cacheKey, (err, res) => {
        if (err) {
          Logger.error(err, 'freshchat')();
        }
        return res !== null ? res : 0;
      });
    },
    openChat() {
      window.fcWidget.open();
      window.fcWidget.show();
    },
    async freshchatSnippet(fcConfig) {
      const user = await this.currentUser;
      const isOpen = await this.getFreshchatStatus();
      const h = document.getElementsByTagName('head')[0];
      const a = document.createElement('script');
      a.async = true;
      a.src = '//wchat.freshchat.com/js/widget.js';
      a.onload = () => {
        window.fcWidget.init({
          token: fcConfig.token,
          host: fcConfig.host,
          open: isOpen,
          config: { headerProperty: { hideChatButton: true } },
          externalId: user ? user.id : '',
          firstName: user ? user.firstname : '',
          lastName: user ? user.lastname : '',
          email: user ? user.email : ''
        });
        window.fcWidget.on('widget:opened', (resp) => {
          this.setFreshchatStatus(1);
        });
        window.fcWidget.on('widget:closed', (resp) => {
          this.setFreshchatStatus(0);
        });
      };
      h.appendChild(a);
    }
  },
  created() {
    if (!isServer) {
      this.freshchatSnippet(config.freshchat);
    }
  }
};
</script>

<style lang="scss" scoped>
.ab-livechat {
  background: #0f1520;
  text-align: center;
  color: #fff;
  padding: 16px;
  position: fixed;
  z-index: 20;
  bottom: 0;
  right: 16px;
  width: 192px;
  box-sizing: border-box;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
  font-size: 20.8px;
  font-weight: 700;
  font-family: univia-pro, Helvetica, Arial, sans-serif;
  line-height: 1.1;
  display: flex;
  align-items: center;
  justify-content: center;
}
svg {
  height: 1.5rem;
  color: white;
  margin-left: 0.5rem;
}
</style>
