<template>
  <modal class="language-popup" name="modal-currencyPopup">
    <div slot="content">
      <div class="content-data" v-if="showSubData">
        <div class="country switcher__section">
          <div class="rc-show-lg">
            {{ $t('My delivery location is') }}
            <span class="country" :class="country"><i /></span>
            <span class="name" @click.prevent="showLocationBlock" data-testid="showLocationBlock">{{ countryName }}</span>
          </div>
          <div class="rc-hide-lg">
            <select @change="selectCountryMob" v-model="selectedCode">
              <option v-for="countryData, index in orderedCountries" :key="`country-code-${countryData.code}-${index}`" :value="countryData.code">
                {{ $t(countryData.name) }}
              </option>
            </select>
          </div>
        </div>
        <div class="currency switcher__section" style="display: none">
          <div class="rc-show-lg">
          {{ $t('My currency is') }}
            <span class="currency" @click.prevent="showCurrencyBlock" data-testid="showCurrencyBlock">{{ currency }} ({{ currencySign }})</span>
          </div>
          <div class="rc-hide-lg">
            <select @change="selectCurrencyMob" v-model="selectedCurrency">
              <option v-for="item in getAvailableCurrencies" :key="`currency-${item.name}`" :value="item.name">
                {{ item.name }} ({{ item.currencySign }})
              </option>
            </select>
          </div>
        </div>
        <div class="lang switcher__section store-locale">
          <div class="rc-show-lg">
            {{ $t('My language is') }}
            <span class="lang" @click.prevent="showLanguagesBlock">{{ currentLanguage }}</span>
          </div>
          <div class="rc-hide-lg">
            <select @change="selectLangMob"  v-model="currentLanguage">
              <option v-for="storeView, storeCode in storeViews" :key="`lang-${storeCode}`" :value="storeView.i18n.fullLanguageName">
                {{ $t(storeView.i18n.fullLanguageName) }}
              </option>
            </select>
          </div>
        </div>
        <a class="no-underline country-save rc-save" href="#" @click.prevent="saveSetting" data-testid="saveSetting">
          {{ $t('Save Setting') }}
        </a>
        <div class="hidden store-data">
          {{ storeViewData }}
        </div>
        <div class="hidden store-data">
          {{ storeViews }}
        </div>
        <div class="mobile-view switcher__section cancel-btn" @click.prevent="close">
          {{ $t('Cancel') }}
          <i class="material-icons cl-accent mr5">close</i>
        </div>
      </div>
      <div class="store-block" v-if="displayLanguagesBlock">
        <div class="country country-current language-block">
          <ul>
            <li @click="hideLanguagesBlock('//' + storeView.url, storeView.i18n.fullLanguageName, storeView.i18n.defaultCountry)"
                data-testid="selectLanguage"
                class="country country-available"
                v-for="(storeView, storeCode) in storeViews"
                :key="storeCode"
                :class="{'current': storeView.i18n.defaultCountry === country }"
            >
              {{ $t(storeView.i18n.fullLanguageName) }}
            </li>
          </ul>
        </div>
      </div>
      <div class="store-block" v-if="displayCurrencyBlock">
        <div class="currency currency-block">
          <div>
            <ul>
              <li class="mb10" v-for="(item) in currentAvailableCurrency" :key="item" :class="{'current':currency===item}">
                <span class="mb10" v-for="(data) in getAvailableCurrencies"
                      :key="data.name"
                      v-if="item === data.name"
                      @click="selectCurrency(item)"
                      data-testid="selectCurrency"
                >
                  {{ item }} ({{ data.currencySign }})
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="store-block" v-if="displayLocationBlock">
        <div class="rc-hide-lg">
          <select @change="selectCountryMob" v-model="selectedCode">
            <option v-for="countryData in getAvailableCountries" :key="countryData.code" :value="countryData.code">
              {{ $t(countryData.name) }}
            </option>
          </select>
          <button type="button" @click="closeLocation" class="rc-button">
            {{ $t('Close') }}
          </button>
        </div>
        <div class="location location-block rc-show-lg">
          <div>
            <ul>
              <li class="store-locale mb10" v-for="(item) in currentAvailableLocation" :key="item" :class="{'current':country===item}">
                <span class="mb10" v-for="(data) in getAvailableCountries"
                      :key="data.code"
                      v-if="item === data.code"
                      @click="selectCountry(data.code, data.name, data.currency)"
                      data-testid="selectCountry"
                >
                  <span class="country" :class="data.code"><i /></span>
                  <span class="name">{{ $t(data.name) }}</span>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import { CurrencyRatesPaths } from '../../../../../../modules/currency-rates/components/CurrencyRates'
import { CoreConfigModule } from '../../../../../../modules/core-config'
import { CurrencyRatesModule } from '../../../../../../modules/currency-rates'
import Modal from 'theme/components/core/Modal.vue'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import config from 'config'
import { registerModule } from '@vue-storefront/core/lib/modules'
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'LanguageSwitcher',
  components: {
    Modal
  },
  mixins: [ CurrencyRatesPaths ],
  data () {
    const storeView = currentStoreView()
    return {
      selectedCode: this.$store.getters['ayko_localisation/getCountry'],
      selectedCurrency: this.$store.getters['ayko_localisation/getCurrency'],
      modalName: 'modal-currencyPopup',
      storeId: storeView.id,
      minCountryPerColumn: 3,
      componentLoaded: false,
      locale: storeView.i18n.defaultLocale,
      currency: this.$store.getters['ayko_localisation/getCurrency'],
      currencySign: this.$store.getters['ayko_localisation/getCurrencySymbol'],
      country: this.$store.getters['ayko_localisation/getCountry'],
      countryName: this.$store.getters['ayko_localisation/getCountryName'],
      lang: storeView.i18n.defaultLanguage,
      store_currency: storeView.i18n.currencyCode,
      locationName: '',
      currencyRate: '',
      defaultLocale: '',
      currentLanguage: storeView.i18n.fullLanguageName,
      currentUrl: '',
      loadLanguagesModal: false,
      storeViewData: storeView.i18n,
      configData: config,
      uk_url: config.uk_url,
      displayLanguagesBlock: false,
      displayCurrencyBlock: false,
      displayLocationBlock: false,
      showSubData: true
    }
  },
  computed: {
    ...mapGetters({
      getConfigItem: 'core_config/getConfigItem',
      getCountry: 'ayko_localisation/getCountry',
      getCountryName: 'ayko_localisation/getCountryName',
      getCurrency: 'ayko_localisation/getCurrency',
      getCurrencySymbol: 'ayko_localisation/getCurrencySymbol',
      getLocale: 'ayko_localisation/getLocale',
      getAvailableCountries: 'ayko_localisation/getAvailableCountries',
      getAvailableCurrencies: 'ayko_localisation/getAvailableCurrencies'
    }),
    orderedCountries () {
      const mainCountries = this.getAvailableCountries.filter((country => {
        return country.code === 'GB' || country.code === 'US' || country.code === 'DE'
      }))
      return [...mainCountries, ...this.getAvailableCountries]
    },
    enableColumns () {
      const enableStoreViews = Object.keys(this.storeViews)
      return enableStoreViews.length > this.minCountryPerColumn
    },
    storeViews () {
      return Object.keys(config.storeViews).reduce((storeViews, storeCode) => {
        if (this.isValidStoreCode(storeCode)) {
          storeViews[storeCode] = config.storeViews[storeCode]
        }
        return storeViews
      }, {})
    },
    currentAvailableCurrency () {
      let currencyData = this.getConfigItem('currency/options/allow');
      let strx = currencyData.split(',');
      let globCurrencyArr = [];
      globCurrencyArr = globCurrencyArr.concat(strx);

      return globCurrencyArr;
    },
    currentAvailableLocation () {
      let locationData = this.getConfigItem('general/country/allow');
      let strx = locationData.split(',');
      let globLocationArr = [];
      globLocationArr = globLocationArr.concat(strx);
      return globLocationArr;
    }
  },
  methods: {
    ...mapActions('graphqlCart', ['clearCartId']),
    ...mapActions({
      setCountry: 'ayko_localisation/setCountry',
      setCurrency: 'ayko_localisation/setCurrency'
    }),
    refreshList () {
      this.$store.dispatch('core_config/list', {})
      this.$store.dispatch('currency_rates/list', {})
    },
    getCurrencyRatesData () {
      return this.$store.state.currency_rates.items
    },
    getCurrentRates (option, store_currency, currency) {
      if ((option.currency_from === store_currency) && option.currency_to === currency) {
        this.currencyRate = option.rate
        return option.rate
      }
    },
    close () {
      this.$bus.$emit('modal-hide', this.modalName)
    },
    showModal (name) {
      if (name === this.modalName) {
        this.displayLocationBlock = false
        this.displayCurrencyBlock = false
        this.displayLanguagesBlock = false
        this.showSubData = true
      }
    },
    hideModal (name) {
      if (name === this.modalName) {
        this.displayLocationBlock = false
        this.displayCurrencyBlock = false
        this.displayLanguagesBlock = false
        this.showSubData = false
      }
    },
    isValidStoreCode (storeCode) {
      const storeView = config.storeViews[storeCode]
      return !!(storeView && typeof storeView === 'object' && storeView.i18n)
    },
    showLanguagesBlock () {
      this.displayLanguagesBlock = true
      this.displayCurrencyBlock = false
      this.showSubData = false
    },
    selectLangMob () {
      const deUrl = `//${this.storeViews.de_de_de.url}`;
      const defaultUrl = `//${this.storeViews.default.url}`;
      this.currentUrl = this.currentLanguage === 'English' ? defaultUrl : deUrl;
    },
    hideLanguagesBlock (url, lang) {
      this.currentLanguage = lang
      this.currentUrl = url
      this.displayLanguagesBlock = false
      this.displayCurrencyBlock = false
      this.showSubData = true
    },
    showCurrencyBlock () {
      this.displayCurrencyBlock = true
      this.displayLanguagesBlock = false
      this.showSubData = false
    },
    selectCurrency (currency) {
      this.currency = currency
      const currencyConfig = config.ayko.localisation.availableCurrencies.find(item => item.name === currency)
      this.currencySign = currencyConfig.currencySign
      this.displayCurrencyBlock = false
      this.showSubData = true
    },
    selectCurrencyMob () {
      const currencyData = this.getAvailableCurrencies.find(x => x.name === this.selectedCurrency)
      this.currency = currencyData.name
      this.currencySign = currencyData.currencySign
      this.displayCurrencyBlock = false
      this.showSubData = true
    },
    selectCountryMob () {
      const country = this.getAvailableCountries.find(x => x.code === this.selectedCode);
      if (!country) {
        return
      }
      this.country = country.code
      this.countryName = country.name
      this.currency = country.currency
      const currencyConfig = config.ayko.localisation.availableCurrencies.find(item => item.name === country.currency)
      if (!currencyConfig) {
        return
      }
      this.currencySign = currencyConfig.currencySign
    },
    closeLocation () {
      this.displayLocationBlock = false;
      this.showSubData = true;
    },
    selectCountry (country, countryName, currency) {
      this.country = country
      this.countryName = countryName
      this.currency = currency
      const currencyConfig = config.ayko.localisation.availableCurrencies.find(item => item.name === currency)
      this.currencySign = currencyConfig.currencySign
      this.displayLocationBlock = false
      this.showSubData = true
    },
    showLocationBlock () {
      this.displayLocationBlock = true
      this.displayCurrencyBlock = false
      this.displayLanguagesBlock = false
      this.showSubData = false
    },
    async saveSetting () {
      this.setCurrency(this.currency)
      const countryChanged = (this.getCountry !== this.country)
      this.setCountry(this.country)
      this.displayLocationBlock = false
      this.displayCurrencyBlock = false
      this.displayLanguagesBlock = false
      this.showSubData = true
      this.close()
      if (this.$route.query.country) {
        const path = this.$route.path;
        this.$router.push({ path: path, query: {country: this.country} });
        // If the country has changed we need to reload the page as tax calculations need rerunning
        this.clearCartId();
        return;
      }
      if (!window.location.href.includes(this.currentUrl)) {
        window.location.href = this.currentUrl;
      } else if (countryChanged) {
        // If the country has changed we need to reload the page as tax calculations need rerunning
        window.location.reload();
        this.clearCartId();
      }
    }
  },
  beforeMount () {
    this.$bus.$on('modal-hide', this.hideModal)
    this.$bus.$on('modal-show', this.showModal)
  },
  beforeDestroy () {
    this.$bus.$off('modal-hide', this.onHide)
  },
  beforeCreate () {
    registerModule(CoreConfigModule)
    registerModule(CurrencyRatesModule)
  }
}
</script>
<style lang="scss" scoped>
.rc-save {
  display: block;
}
.switcher__section {
  display: block;
  color: #4f4f4f !important;
}
.rc-button {
  width: 100%;
  padding: 10px;
  color: white;
  margin-top: 20px;
  text-align: center;
  text-transform: uppercase;
}
.store-block {
  height: 100vh;
}
  .rc-show-lg {
    display: none;
  }
  @media (min-width: 1024px) {
    .switcher__section {
      display: inline-block;
    }
    .rc-save {
      display: inline;
    }
    .rc-show-lg {
      display: block;
    }
    .rc-hide-lg {
      display: none;
    }
  }
  .language-popup {
    z-index: 20;
  }
  h3 {
    margin-top: 0;
    margin-bottom: 0.5em;
  }
  .columns {
    -moz-column-count: 2;
    column-count: 2;
    column-gap: 15px;
    .country {
      -webkit-column-break-inside: avoid;
      page-break-inside: avoid;
      break-inside: avoid;
    }
  }
  .country {
    margin-bottom: 2em;
    color: #4f4f4f;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    margin-left: -1em;
    li {
      display: inline-block;
      margin-left: 1em;
      a {
        font-size: 0.9em;
      }
    }
  }
</style>
