<template>
  <article class="p-5 px-10 border">
    <header class="flex items-center justify-between my-5">
      <h2 class="m-0 font-featured text-2xl font-bold uppercase">
        {{ $t('Reset Password') }}
      </h2>
      <button type="button" @click="closeModal">
        <CloseIcon />
      </button>
    </header>
    <form @submit.prevent="handleSubmit" class="mb-5">
      <div class="mb-5">
        <label for="email" class="sr-only">Email</label>
        <input
          type="email"
          id="email"
          v-model="email"
          placeholder="Email address *"
          class="w-full border p-2"
        />
        <Transition name="fade">
          <p v-if="showEmailError" class="text-xs my-1 mx-1 text-red-500">
            {{ $t('Field is required') }}
          </p>
        </Transition>
      </div>
      <button
        type="submit"
        class="border border-black bg-black text-white uppercase text-center p-3 w-full font-featured flex items-center justify-center gap-1 hover:bg-gray-900"
      >
        <span>{{ $t('Reset Password') }}</span>
        <ArrowRightIcon />
      </button>
      <div class="my-5">
        <button type="button" @click="goToLogin">
          {{ $t('or log in to your account') }}
        </button>
      </div>
      <Transition name="fade">
        <p v-if="getLoadingAccount" class="my-5 text-center">Loading...</p>
      </Transition>
      <Transition name="fade">
        <p v-if="getAccountErrors" class="my-5 text-center text-red-500">
          {{ getAccountErrors }}
        </p>
      </Transition>
      <Transition name="fade">
        <p v-if="getAccountMessage" class="my-5 text-center text-blue-500">
          {{ getAccountMessage }}
        </p>
      </Transition>
    </form>
  </article>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CloseIcon from 'theme/components/theme/bikebuilder/graphql-auth/icons/CloseIcon.vue';
import ArrowRightIcon from 'theme/components/theme/bikebuilder/graphql-auth/icons/ArrowRightIcon.vue';

export default {
  name: 'RibbleResetPassword',
  components: {
    CloseIcon,
    ArrowRightIcon
  },
  data() {
    return {
      email: ''
    };
  },
  computed: {
    ...mapGetters('graphqlAccount', [
      'getLoadingAccount',
      'getAccountErrors',
      'getAccountMessage'
    ]),
    showEmailError() {
      return !!(this.email === '');
    }
  },
  methods: {
    ...mapActions('graphqlAccount', ['requestPasswordResetEmail']),
    closeModal() {
      this.$bus.$emit('modal-hide', 'modal-signup');
    },
    goToLogin() {
      this.$store.commit('ui/setAuthElem', 'login');
    },
    async handleSubmit() {
      await this.requestPasswordResetEmail(this.email);
    }
  }
};
</script>
