<template>
  <div>
    <a href="#" class="store-locale" @click.prevent="showLanguagesModal" data-testid="showLanguagesModal">
      <span class="currency">{{ getCurrency }} ({{ getCurrencySymbol }})</span>
      <span class="country" :class="getCountry" v-if="getCountry">
        <i />
      </span>
    </a>
  </div>
</template>

<script>
import {mapGetters, mapState, mapActions} from 'vuex'

export default {
  computed: {
    ...mapState(['ayko_localisation']),
    ...mapGetters({
      getCountry: 'ayko_localisation/getCountry',
      getCurrency: 'ayko_localisation/getCurrency',
      getCurrencySymbol: 'ayko_localisation/getCurrencySymbol'
    })
  },
  methods: {
    ...mapActions('craftCms', ['closeMobileMenu']),
    showLanguagesModal () {
      this.$bus.$emit('modal-show', 'modal-currencyPopup')
      this.closeMobileMenu()
    }
  }
}
</script>
<style lang="scss" scoped>
a {
  color: #D0D5DF;
}
</style>
