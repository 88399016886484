<template>
  <ul>
    <li v-if="showOldCheckout" class="mb-2">
      <button
        type="button"
        @click="handleCheckout"
        :disabled="disableBtn"
        :style="
          disableBtn ? 'opacity: 0.5; cursor: not-allowed; important;' : null
        "
        class="border border-black bg-black text-white uppercase text-center p-3 w-full font-featured flex items-center justify-center gap-1"
      >
        <LockIcon />
        <span class="old">Checkout</span>
        <LoadingIcon v-if="waitingCheckout" class="rc-loader text-white" />
        <ArrowRightIcon v-else />
      </button>
    </li>
    <li v-if="showNewCheckout" class="mb-2">
      <button
        type="button"
        @click="handleNewCheckout"
        :disabled="disableBtn"
        :style="
          disableBtn ? 'opacity: 0.5; cursor: not-allowed; important;' : null
        "
        class="border border-black bg-black text-white uppercase text-center p-3 w-full font-featured flex items-center justify-center gap-1"
      >
        <LockIcon />
        <span class="new">Checkout</span>
        <LoadingIcon v-if="waitingCheckout" class="rc-loader text-white" />
        <ArrowRightIcon v-else />
      </button>
    </li>
  </ul>
</template>

<script>
import config from 'config';
import { mapGetters, mapActions } from 'vuex';
import LockIcon from './icons/LockIcon.vue';
import LoadingIcon from './icons/LoadingIcon.vue';
import ArrowRightIcon from './icons/ArrowRightIcon.vue';
import bannedShippingMixin from 'theme/mixins/bannedShippingMixin';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus';

export default {
  name: 'CheckoutBtn',
  props: {
    checkBikeCount: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      waitingCheckout: false,
      checkoutVersion: 'old' // default to old
    };
  },
  components: { LockIcon, LoadingIcon, ArrowRightIcon },
  mixins: [bannedShippingMixin],
  computed: {
    ...mapGetters('storeIp', ['getIsStoreIp']),
    ...mapGetters('newCheckout', ['getNewStoreConfig']),
    ...mapGetters('graphqlAccount', ['isLoggedIn']),
    ...mapGetters('graphqlCart', [
      'getCartItems',
      'getCartBikeCount',
      'getCartDisplay',
      'getLoadingCart'
    ]),
     ...mapGetters('esw', [
      'useEsw'
    ]),
    bannedShippingProductInCart() {
      const products = this.getCartItems.map((item) => item.product);
      if (!products || !products.length) return false;
      return this.checkProductsForBannedShipping(products); // mixin method
    },
    disableBtn() {
      if (
        this.bannedShippingProductInCart ||
        this.waitingCheckout ||
        this.getLoadingCart
      )
        return true;
      if (this.checkBikeCount && this.getCartBikeCount > 1) return true;
      return false;
    },
    showOldCheckout() {
      return !this.getNewStoreConfig || (this.checkoutVersion === 'old' || this.useEsw || this.getIsStoreIp);
    },
    showNewCheckout() {
      return this.getNewStoreConfig && (this.checkoutVersion === 'new' || this.getIsStoreIp) && !this.useEsw;
    }
  },
  methods: {
    ...mapActions('graphqlAccount', ['setRedirectCheckout']),
    ...mapActions('newCheckout', ['fetchNewCheckout']),
    handleCheckout() {
      if (
        this.$route.name === 'ribbleUpsell' &&
        this.getCartBikeCount > 1 &&
        this.getCartDisplay === false
      ) {
        this.$store.dispatch('graphqlCart/toggleCart');
        return;
      }
      if (this.isLoggedIn) {
        this.waitingCheckout = true;
        this.$router.push({ name: 'checkout' }).catch((err) => {});
      } else {
        this.setRedirectCheckout(true);
        this.$store.dispatch('graphqlAccount/setRedirectCheckoutVersion', "old");
        EventBus.$emit('modal-show', 'modal-signup');
      }
    },
    handleNewCheckout() {
      if (
        this.$route.name === 'ribbleUpsell' &&
        this.getCartBikeCount > 1 &&
        this.getCartDisplay === false
      ) {
        this.$store.dispatch('graphqlCart/toggleCart');
        return;
      }
      if (this.isLoggedIn) {
        this.waitingCheckout = true;
        window.location.replace(config.newCheckoutUrl);
        return;
      }
      if (!this.isLoggedIn) {
        this.setRedirectCheckout(true);
        this.$store.dispatch('graphqlAccount/setRedirectCheckoutVersion', "new");
        EventBus.$emit('modal-show', 'modal-signup');
      }
    },
    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    }
  },
  async mounted() {
    this.waitingCheckout = false;

    // Check for bloomreach-checkout cookie
    const checkoutCookie = this.getCookie('bloomreach-checkout');
    this.checkoutVersion = checkoutCookie === 'new' ? 'new' : 'old';

    await this.fetchNewCheckout();
  }
};
</script>