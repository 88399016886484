import CurrencyRates from '../types/CurrencyRates'

export const CurrencyRatesPaths = {
  name: 'CurrencyRates',
  computed: {
    currency_rates_paths (): CurrencyRates[] {
      return this.$store.state.currency_rates.items
    }
  }
}
