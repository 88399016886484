<template></template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'GoInStore',
  computed: {
    ...mapGetters({
      getConfigItem: ['core_config/getConfigItem']
    }),
    ...mapGetters('product', ['getCurrentProduct']),
    ...mapGetters('category-next', ['getCurrentCategory']),
    isProductPage() {
      return !!(this.$route.params.childSku || this.$route.params.parentSku);
    },
    isRibbleUpsell() {
      return this.$route.name === 'ribbleUpsell';
    },
    isCategoryPage() {
      return !!(
        this.getCurrentCategory && Object.keys(this.getCurrentCategory).length
      );
    },
    isConfigurator() {
      return this.$route.name === 'configurator';
    },
    showGoInStore() {
      const goInStorePages = ['home', 'urldispatcher-/liveinstoreexperts'];

      if (!this.isEnabled) return false;

      if (this.isConfigurator) return false;

      if (this.isCategoryPage) return true;

      if (this.isProductPage) return true;

      if (this.isRibbleUpsell) return true;

      if (goInStorePages.includes(this.$route.name)) return true;

      // TODO GO IN STORE HAS OPENING TIMES TO CHECK

      return false;
    },
    isEnabled() {
      const config = this.getConfigItem('go_in_store/general/is_enabled');
      return config && parseInt(config) === 1;
    }
  },
  mounted() {
    const gisIconShow = '.gis-cta-reset { display:block !important; }';
    const gisIconHide = '.gis-cta-reset { display:none !important; }';

    const gisVideoShow = '#gis-cta-video { display:block !important; }';
    const gisVideoHide = '#gis-cta-video { display:none !important; }';
    if (this.showGoInStore) {
      document.getElementById(
        'gis-styles'
      ).innerHTML = `${gisIconShow} ${gisVideoShow}`;
    } else {
      document.getElementById(
        'gis-styles'
      ).innerHTML = `${gisIconHide} ${gisVideoHide}`;
    }
  }
};
</script>
