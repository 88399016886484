<template>
  <div>
    <div
      :class="['cms-content', { 'container': sync }, { 'cms-content-styled': styleContent}]"
      v-if="getCmsData && getCmsData.content"
      v-interpolation v-inject-price v-html="sanitize(getCmsData.content)"
    />
    <div
      :class="['cms-content', 'hidden', { 'container': sync }, { 'cms-content-styled': styleContent}]"
      v-else
    >
      {{ noCmsBlockError }}
    </div>
  </div>
</template>
<script>
import { isServer } from '@vue-storefront/core/helpers'
import interpolation from 'theme/components/theme/directives/interpolation'
import injectPrice from 'theme/components/theme/directives/injectPrice'

export default {
  name: 'CmsBlock',
  directives: {
    interpolation,
    injectPrice
  },
  props: {
    id: {
      type: Number,
      default: null,
      required: false
    },
    identifier: {
      type: String,
      default: null,
      required: false
    },
    sync: {
      type: Boolean,
      default: false,
      required: false
    },
    styleContent: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  serverPrefetch () {
    return this.fetchCmsBlock()
  },
  created () {
    if (!isServer) {
      this.fetchCmsBlock()
    }
  },
  methods: {
    sanitize (content) {
      const match = content.match(/<script.*>/);
      if (match) {
        content = content.replace('<script', '<!-- <script');

        content = content.replace('<\/script>', '<\/script> -->'); // eslint-disable-line no-useless-escape
        console.info('Your CMS block has been sanitized client side');
      }
      return content;
    },
    fetchCmsBlock () {
      let queryKey = ''
      let queryValue = ''
      if (this.id) {
        queryKey = 'id'
        queryValue = this.id
      } else if (this.identifier) {
        queryKey = 'identifier'
        queryValue = this.identifier
      }
      if (queryKey && queryValue) {
        const content = this.$store.dispatch('cmsBlock/single', {
          key: queryKey,
          value: queryValue,
          skipCache: false
        }).then(contentBlock => {
          if (contentBlock) {
            content.content = this.sanitize(contentBlock.content);
          }
        }).catch(error => {
          console.error(error);
        });
        return content;
      }
    },
  },
  computed: {
    noCmsBlockError () {
      if (process.env.VS_ENV !== 'prod') {
        return 'Can not load CMS Block ' + (this.id || this.identifier)
      }
      return ''
    },
    getCmsData () {
      if (this.id) {
        return this.$store.getters[`cmsBlock/getCmsBlockById`](this.id)
      } else if (this.identifier) {
        return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](this.identifier)
      }
      return null
    },
  }
}
</script>
