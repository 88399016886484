<template>
  <div></div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isServer } from '@vue-storefront/core/helpers';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';

export default {
  name: 'CartTrack',
  props: {
    product: {
      type: Object,
      required: true
    },
    action: {
      type: String, // add / remove
      required: true
    },
    btnText: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters('attribute', ['getAttributeListByCode']),
    ...mapGetters('esw', [
      'getUserCountry',
      'getUserCurrency',
      'getUserLocale'
    ]),
    ...mapGetters('graphqlCart', ['getCartItemsCount', 'getCartItemsNoCC', 'getCartTotal']),
    categories() {
      if (!this.product.category || !this.product.category.length) return [];
      return this.product.category.sort((a, b) => a.position - b.position);
    },
    tags() {
      return this.categories.map((cat) => cat.name);
    },
    cartItems() {
      return this.getCartItemsNoCC.map((item) => {
        return {
          product_id: item.product.sku,
          quantity: item.product.quantity
        };
      });
    },
    cartSkus() {
      return this.getCartItemsNoCC.map((item) => item.product.sku);
    },
    domain() {
      const store = currentStoreView();
      let url = store && store.url ? store.url : '';

      // TODO temp fix while Gareth is away
      if (url === 'ribblecycles.local.com') {
        url = 'www.ribblecycles.co.uk';
      }

      return url;
    },
    categoryOneId() {
      let label = '';
      if (isNaN(this.product.category_level_1)) { //If Graphql we already have the value
        label = this.product.category_level_1;
      } else { //If not we need to look it up
        const category = this.getAttributeListByCode['category_level_1'];
        const options = category.options;
        const matchingOption = options.find(option => option.value === String(this.product.category_level_1));
        label = matchingOption ? matchingOption.label : 'Category not found';
      }
      return label;
    },
    categoryTwoId() {
      let label = '';
      if (isNaN(this.product.category_level_2)) {
        label = this.product.category_level_2;
      } else {
        const category = this.getAttributeListByCode['category_level_2'];
        const options = category.options;
        const matchingOption = options.find(option => option.value === String(this.product.category_level_2));
        label = matchingOption ? matchingOption.label : 'Category not found';
      }
      return label;
    },
    categoryThreeId() {
      let label = '';
      if (isNaN(this.product.category_level_3)) {
        label = this.product.category_level_3;
      } else {
        const category = this.getAttributeListByCode['category_level_3'];
        const options = category.options;
        const matchingOption = options.find(option => option.value === String(this.product.category_level_3));
        label = matchingOption ? matchingOption.label : 'Category not found';
      }
      return label;
    },
    originalPrice() {
      if (this.product.original_price_incl_tax) {
        return this.product.original_price_incl_tax;
      }
      if (
        this.product.price_range &&
        this.product.price_range.default_price &&
        this.product.price_range.default_price.final_price
      ) {
        return this.product.price_range.default_price.final_price.value;
      }
      return '';
    },
    specialPrice() {
      // simple products have a data structure like this from elastic search
      if (this.product.special_price_incl_tax) {
        return this.product.special_price_incl_tax;
      }
      // bikes have a data structure like this from graphql
      if (
        this.product.price_range &&
        this.product.price_range.default_special_price &&
        this.product.price_range.default_special_price.final_price
      ) {
        return this.product.price_range.default_special_price.final_price.value;
      }
      // cart products probably have a different data structure but still todo
      return '';
    },
    discount() {
      if (!this.specialPrice) return 0;
      return this.originalPrice - this.specialPrice;
    },
    discountPercentage() {
      if (!this.specialPrice) return 0;
      return Math.round((this.discount / this.originalPrice) * 100);
    },
    payload() {
      return {
        action: this.action, // prop
        button_copy: this.btnText, // prop
        page_type: '', // Type of a page where cart contents changed.(e.g. product, cart, homepage)
        product_id: this.product.sku,
        title: this.product.name,
        brand: '',
        price: this.specialPrice ? this.specialPrice : this.originalPrice,
        price_local_currency: this.specialPrice || this.originalPrice,
        discount_percentage: this.discountPercentage,
        discount_value: this.discount,
        original_price: this.originalPrice,
        original_price_local_currency: this.originalPrice,
        product_list: this.cartItems, // List of all products in the cart after cart update.
        product_ids: this.cartSkus, // List of IDs of all products in the cart after cart update.
        total_quantity: this.getCartItemsCount,
        total_price: this.getCartTotal, // Total price of the products in the cart in the reference currency.
        total_price_without_tax: '', // Total price of items in the cart in the reference currency including the shipping but not the tax.
        total_price_local_currency: this.getCartTotal, // Total price of items in the cart in the currency the customer will use for the payment.
        local_currency: this.getUserCurrency,
        tags: this.tags,
        category_level_1: this.categoryOneId,
        category_level_2: this.categoryTwoId,
        category_level_3: this.categoryThreeId,
        categories_path: '',
        category_ids: '',
        categories_ids: '',
        language: this.getUserLocale,
        location: `${this.domain}${this.$route.fullPath}`,
        domain: this.domain
      };
    }
  },
  methods: {
    track() {
      if (isServer) return;
      // can only run client side as it uses window
      window.exponea.track('cart_update', this.payload);
    }
  },
  mounted() {
    console.info('CartTrack mounted');
  },
  created() {
    this.track();
  }
};
</script>
