<template>
  <article class="p-5 px-10 border">
    <header class="flex items-center justify-between my-5">
      <h2 class="m-0 font-featured text-2xl font-bold uppercase">
        {{ $t('Log in') }}
      </h2>
      <button type="button" @click="closeModal">
        <CloseIcon />
      </button>
    </header>
    <form @submit.prevent="handleSubmit" class="mb-5">
      <div class="mb-5">
        <label for="email" class="sr-only">Email</label>
        <input
          type="email"
          id="email"
          v-model="email"
          class="w-full border p-2"
          placeholder="Email address *"
        />
      </div>
      <div class="mb-5">
        <label for="password" class="sr-only">Password</label>
        <input
          type="password"
          id="password"
          v-model="password"
          class="w-full border p-2"
          placeholder="Password *"
        />
      </div>
      <div class="mb-5 flex justify-end items-center">
        <button type="button" @click="goToForgotPasswordPassword">
          {{ $t('Forgot the password?') }}
        </button>
      </div>
      <button
        type="submit"
        class="border border-black bg-black text-white uppercase text-center p-3 w-full font-featured flex items-center justify-center gap-1 hover:bg-gray-900"
      >
        <span class="leading-none">{{ $t('Log in') }}</span>
        <ArrowRightIcon />
      </button>
      <div class="my-5">
        <button type="button" @click="goToRegisterAccount">
          {{ $t('or register an account') }}
        </button>
      </div>
      <Transition name="fade">
        <p v-if="getLoadingAccount" class="my-5 text-center">Loading...</p>
      </Transition>
      <Transition name="fade">
        <p v-if="getAccountErrors" class="my-5 text-center text-red-500">
          {{ getAccountErrors }}
        </p>
      </Transition>
    </form>
  </article>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CloseIcon from 'theme/components/theme/bikebuilder/graphql-cart/icons/CloseIcon.vue';
import ArrowRightIcon from 'theme/components/theme/bikebuilder/graphql-auth/icons/ArrowRightIcon.vue';

export default {
  name: 'RibbleLogin',
  components: {
    CloseIcon,
    ArrowRightIcon
  },
  data() {
    return {
      email: '',
      password: ''
    };
  },
  computed: {
    ...mapGetters('graphqlAccount', ['getLoadingAccount', 'getAccountErrors'])
  },
  methods: {
    ...mapActions('graphqlAccount', ['login']),
    closeModal() {
      this.$bus.$emit('modal-hide', 'modal-signup');
    },
    goToRegisterAccount() {
      this.$store.commit('ui/setAuthElem', 'register');
    },
    goToForgotPasswordPassword() {
      this.$store.commit('ui/setAuthElem', 'forgot-pass');
    },
    async handleSubmit() {
      const payload = {
        email: this.email,
        password: this.password
      };
      this.login(payload).then(() => {
        if (!this.getLoadingAccount && !this.getAccountErrors) {
          window.exponea.identify({
            email_id: this.email
          });
          this.email = '';
          this.password = '';
          this.$bus.$emit('modal-hide', 'modal-signup');
        }
      });
    }
  }
};
</script>
