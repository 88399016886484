<template>
  <article>
    <h3
      v-if="bike && bike.name"
      class="m-0 mb-5 uppercase font-bold font-featured text-xl"
    >
      {{ bike.name }}
    </h3>
    <div class="max-h-[600px] overflow-y-scroll">
      <FullSpecList
      v-if="fullSpecBySection"
      :fullSpecBySection="fullSpecBySection"
    />
    </div>
  </article>
</template>

<script>
import { mapGetters } from 'vuex';
import FullSpecList from './FullSpecList.vue';

export default {
  name: 'FullSpecCart',
  components: {
    FullSpecList
  },
  computed: {
    ...mapGetters('graphqlCart', ['getCartItems']),
    ...mapGetters('builderUi', ['getSelectedCartUid']),
    getSelectedFullSpec() {
      return this.getSelectedCartUid;
    },
    bike() {
      return this.getCartItems && this.getSelectedCartUid
        ? this.getCartItems.find(
            (option) => option.uid === this.getSelectedCartUid
          )
        : null;
    },
    fullSpec() {
      if (!this.bike) return null;
      return this.bike.full_spec ? JSON.parse(this.bike.full_spec) : null;
    },
    fullSpecBySection() {
      if (!this.fullSpec) return null;
      return Object.entries(this.fullSpec).map(([label, values]) => ({
        label,
        values
      }));
    }
  }
};
</script>
